import { Dispatch, SetStateAction } from "react";
import { StyledSelect, StyledTextField } from "../..";

interface ChildComponentProps {
    organizationName: string;
    setOrganizationName: Dispatch<SetStateAction<string>>;
    organizationSize: string;
    setOrganizationSize: Dispatch<SetStateAction<string>>;
}

const CompanyDetails: React.FC<ChildComponentProps> = ({organizationName, setOrganizationName, organizationSize, setOrganizationSize}) => {
    const selectValues = [{value: '1-5', label: '1-5'}, {value: '5-10', label: '5-10'}, {value: '10-100', label: '10-100'}, {value: '100-1000', label: '100-1000'}, {value: '1000+', label: '1000+'}]

    return (
        <div>
            <StyledTextField 
                value={organizationName}
                label={"Company Name"}
                onChange={(event: { target: { value: string; }; }) => setOrganizationName(event.target.value)} 
            />
            <StyledSelect selectValues={selectValues} value={organizationSize} setValue={setOrganizationSize} title={"Company size"} label={"Company Size"}/>
        </div>
    );
};

export default CompanyDetails;