import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '../../themes/ThemeProvider';

interface CircleIconProps {
    weight?: 'fill' | 'outline';
}

const CircleIcon: React.FC<CircleIconProps> = ({ weight }) => {
    const { currentTheme } = useTheme();
    return (
        <CheckCircleIcon
            sx={{ fontWeight: weight === 'fill' ? 'bold' : 'normal', color: currentTheme.onPrimary, fontSize: { xs: 20, sm: 20}}}
        />
    );
};

export default CircleIcon;