import { Dispatch, SetStateAction } from "react";
import { StyledButton } from "../../../buttons";
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";
import { useTheme } from "../../../../themes/ThemeProvider";
import fetchData from "../../../../helpers/requests";
import { useAuth0 } from "@auth0/auth0-react";

interface ChildComponentProps {
    domain: string;
    handlePrevious: () => void;
    handleNext: () => void;
    firstName: string;
    setFirstName: Dispatch<SetStateAction<string>>;
    lastName: string;
    setLastName: Dispatch<SetStateAction<string>>;
    title: string;
    setTitle: Dispatch<SetStateAction<string>>;
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
    organizationName: string;
    setOrganizationName: Dispatch<SetStateAction<string>>;
    organizationSize: string;
    setOrganizationSize: Dispatch<SetStateAction<string>>;
}

const CompanySetup: React.FC<ChildComponentProps> = ({ domain, handlePrevious, handleNext, firstName, setFirstName, lastName, setLastName, title, setTitle, email, setEmail, organizationName, setOrganizationName, organizationSize, setOrganizationSize }) => {
    const { config, setConfig } = useTheme();
    const { getAccessTokenSilently } = useAuth0();   

    const handleContinue = async () => {
        let newConfig = config;

        newConfig["user"] = {
            "firstName": firstName,
            "lastName": lastName,
            "title": title,
            "email": email,
        }

        newConfig["organization"] = {
            "name": organizationName,
            "size": organizationSize,
        }

        const stringifiedDomainConfig = JSON.stringify(newConfig)

        setConfig(newConfig);
        localStorage.setItem("config", stringifiedDomainConfig)

        const token = await getAccessTokenSilently();

        const result = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config/update", 
            method: "POST",
            domain: domain,
            token: token,
            code: undefined,
            body: {config:{ addZendeskConfig: newConfig }},
        });
        handleNext()
    }

    return (
        <div>
            <UserDetails firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} title={title} setTitle={setTitle} email={email} setEmail={setEmail} centered={false} />
            <CompanyDetails organizationName={organizationName} setOrganizationName={setOrganizationName} organizationSize={organizationSize} setOrganizationSize={setOrganizationSize} />
            <StyledButton
                onClick={ handlePrevious }
                text={'Back'}
                enabled={true}
            /> 
            <StyledButton
                onClick={handleContinue}
                text={'Continue'}
                enabled={true}
            /> 
        </div>
    );
}
  
export default CompanySetup;