import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '../../themes/ThemeProvider';

const ConnectionCard = (props: any) => {
    const { component, height } = props;
    const { currentTheme } = useTheme();

    return (
        <Card sx={{
            minHeight: height ? height : 100,
            maxHeight: height ? height : 100,
            margin: '3px',
            backgroundColor: currentTheme.raised,
            color: currentTheme.onRaised,
            border: "2px solid " + currentTheme.secondary
        }}>
            <CardContent>
                {component}
            </CardContent>
        </Card>
    );
}

export default ConnectionCard;