import { FormControlLabel, Switch } from "@mui/material";
import { useTheme } from "../../../../themes/ThemeProvider";
import { StyledButton } from "../../../buttons";
import StyledTextField from "../../StyledTextField";
import fetchData from "../../../../helpers/requests";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { checkValidUsers, isEmailValid, isNameValid } from "../../../../helpers/functions";

interface ChildComponentProps {
    usersToInvite: any;
    setUsersToInvite: any;
    handleNext: () => void;
    handlePrevious: () => void;
    domain: string;
    defaultUsers: any
}

interface Person {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    admin: boolean;
    errorStatus: any
}


function rowIsEmpty(row: Person) {
    let rowEmpty = true;

    for (let key in row) {
        if (row.hasOwnProperty(key) && key === "email" || key === "firstName" || key === "lastName" || key === "title") {
            if (row[key] !== "") {
                return false;
            }
        }
    }
    return rowEmpty
}

function removeAllErrorsFromRow(rows: Person[], index: number) {
    const newArray: Person[] = [...rows];

    for (let key in newArray[index]) {
        if (newArray[index].hasOwnProperty(key) && key === "email" || key === "firstName" || key === "lastName" || key === "title") {
            newArray[index].errorStatus[key] = false;
        }
    }
    return newArray;
}

const changeValueAtIndex = (originalArray: Person[], index: number, key: string, newValue: string | boolean): Person[] => {
    // Make a copy of the original array to avoid mutating it directly
    let newArray: Person[] = [...originalArray];

    // Check if the index is within the bounds of the array
    if (index < 0 || index >= newArray.length) {
        console.error("Index is out of bounds");
        return newArray; // Return the original array if index is invalid
    }

    let stringValue = "";
    if(typeof newValue === "string") {
        stringValue = newValue;
    }

    let error = false;

    if (key === "email") {
        error = !isEmailValid(stringValue)
    } else if (key === "firstName" || key === "lastName") {
        error = !isNameValid(stringValue)
    }

    newArray[index] = {
        ...newArray[index], // Keep other properties unchanged
        [key]: newValue
    }// Update the name & error property

    if (typeof newValue === "string" && stringValue.length === 0 && rowIsEmpty(newArray[index])) {
        newArray = removeAllErrorsFromRow(newArray, index)
        newArray[index].errorStatus[key] = false;
    } else {
        newArray[index].errorStatus[key] = error;
    }

    return newArray;
}

function hasTrueValues(obj: any) {
    for (let key in obj) {

        if (obj.hasOwnProperty(key) && obj[key] === true) {
            return true;
        }
    }
    return false;
}

const containsErrors = (newUsersToInvite: Person[]) => {
    for (let i = 0; i < newUsersToInvite.length; i++) {
        if (hasTrueValues(newUsersToInvite[i].errorStatus)) {
            return true;
        }
    }
    return false;
}

const InviteUsers: React.FC<ChildComponentProps> = ({ usersToInvite, setUsersToInvite, handlePrevious, handleNext, domain, defaultUsers }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentTheme } = useTheme();
    const [error, setError] = useState(false)
    const [emailsSent, setEmailsSent] = useState(false)
    const [emailsError, setEmailsError] = useState(false)
    const [numEmailsSent, setNumEmailsSent] = useState(0)

    const centeredStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    const setVariable = (index: number, key: string, newName: string | boolean) => {

        let newUsersToInvite = changeValueAtIndex(usersToInvite, index, key, newName)

        setUsersToInvite(newUsersToInvite);

        setError(containsErrors(newUsersToInvite))
    }

    const sendInvites = async () => {
        if (!error) {
            const token = await getAccessTokenSilently();

            const validUsersToInvite = checkValidUsers(usersToInvite);

            setNumEmailsSent(validUsersToInvite.length)

            if (validUsersToInvite.length > 0) {
                // only send valid emails
                const result = await fetchData({
                    url: import.meta.env.VITE_UNEVU_API_URL + "/user/invite",
                    method: "POST",
                    domain: domain,
                    token: token,
                    code: 'null',
                    body: { users: validUsersToInvite }
                });

                if (result.status === 200) {
                    setEmailsSent(true)
                    setEmailsError(false)
                    setUsersToInvite(defaultUsers)
                } else {
                    setEmailsError(true)
                    setEmailsSent(false)
                }
            } else {
                setEmailsError(true)
            }

        } else {
            // create modal that says "Resolve all errors before sending email"
        }
    }

    return (
        <div>
            <div style={centeredStyle}>
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[0].firstName}
                    label={"First Name"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(0, "firstName", event.target.value)}
                    error={usersToInvite[0].errorStatus["firstName"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[0].lastName}
                    label={"Last Name"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(0, "lastName", event.target.value)}
                    error={usersToInvite[0].errorStatus["lastName"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[0].title}
                    label={"Title"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(0, "title", event.target.value)}
                    error={usersToInvite[0].errorStatus["title"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[0].email}
                    label={"Email"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(0, "email", event.target.value)}
                    error={usersToInvite[0].errorStatus["email"]}
                />
                <FormControlLabel style={{ color: currentTheme.primary }}
                    control={
                        <Switch
                            style={{ color: currentTheme.primary }}
                            onChange={(event: { target: { checked: boolean; }; }) => {
                                setVariable(0, "admin", event.target.checked)
                            }}
                        />
                    }
                    label="Admin"
                />
            </div>
            <div style={centeredStyle}>
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[1].firstName}
                    label={"First Name"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(1, "firstName", event.target.value)}
                    error={usersToInvite[1].errorStatus["firstName"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[1].lastName}
                    label={"Last Name"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(1, "lastName", event.target.value)}
                    error={usersToInvite[1].errorStatus["lastName"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[1].title}
                    label={"Title"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(1, "title", event.target.value)}
                    error={usersToInvite[1].errorStatus["title"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[1].email}
                    label={"Email"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(1, "email", event.target.value)}
                    error={usersToInvite[1].errorStatus["email"]}
                />
                <FormControlLabel style={{ color: currentTheme.primary }}
                    control={
                        <Switch
                            style={{ color: currentTheme.primary }}
                            onChange={(event: { target: { checked: boolean; }; }) => {
                                setVariable(1, "admin", event.target.checked)
                            }}
                        />
                    }
                    label="Admin"
                />
            </div>
            <div style={centeredStyle}>
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[2].firstName}
                    label={"First Name"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(2, "firstName", event.target.value)}
                    error={usersToInvite[2].errorStatus["firstName"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[2].lastName}
                    label={"Last Name"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(2, "lastName", event.target.value)}
                    error={usersToInvite[2].errorStatus["lastName"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[2].title}
                    label={"Title"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(2, "title", event.target.value)}
                    error={usersToInvite[2].errorStatus["title"]}
                />
                <StyledTextField
                    width={'20ch'}
                    value={usersToInvite[2].email}
                    label={"Email"}
                    onChange={(event: { target: { value: string | boolean; }; }) => setVariable(2, "email", event.target.value)}
                    error={usersToInvite[2].errorStatus["email"]}
                />
                <FormControlLabel style={{ color: currentTheme.primary }}
                    control={
                        <Switch
                            style={{ color: currentTheme.primary }}
                            onChange={(event: { target: { checked: boolean; }; }) => {
                                setVariable(2, "admin", event.target.checked)
                            }}
                        />
                    }
                    label="Admin"
                />
            </div>
            <StyledButton
                onClick={handlePrevious}
                text={'Back'}
                enabled={true}
            />
            <StyledButton
                onClick={sendInvites}
                text={'Send Invite(s)'}
                enabled={!error}
            />
            <StyledButton
                onClick={handleNext}
                text={'Skip Ahead'}
                enabled={true}
            />
        </div>
    );
}

export default InviteUsers;