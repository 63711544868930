import { Dispatch, SetStateAction, useState } from "react";
import { StyledButton, TestConnectionButton } from "../../../buttons";
import { useTheme } from "../../../../themes/ThemeProvider";
// @ts-ignore
import IntegrationsList from "./IntegrationsList";

interface ChildComponentProps {
    handleNext: () => void;
    domain: string;
    setDomain: Dispatch<SetStateAction<string>>;
    apikey: string | undefined;
    setApikey: Dispatch<SetStateAction<string>>;
    apikeyEmail: string | undefined;
    setApikeyEmail: Dispatch<SetStateAction<string>>;
}

const ConnectionSetup: React.FC<ChildComponentProps> = ({ handleNext, domain, setDomain, apikey, setApikey, apikeyEmail, setApikeyEmail }) => {
    const [testPassed, setTestPassed] = useState();
    const { config } = useTheme();

    const handleClick = () => {
        handleNext()
    }

    return (
        <div>
            <IntegrationsList
                domain={domain}
                setDomain={setDomain}
                apikey={apikey}
                setApikey={setApikey}
                apikeyEmail={apikeyEmail}
                setApikeyEmail={setApikeyEmail}
            />

            {config?.loggedIn && config?.connection?.type === "oauth" ?
                <TestConnectionButton
                    connectionType={'oauth'}
                    adminEmail={apikeyEmail}
                    domain={domain}
                    apikey={apikey}
                    setTestPassed={setTestPassed}
                />
                : null}

            {config?.connected ?
                <StyledButton
                    onClick={handleClick}
                    text={'Continue'}
                    enabled={true}
                /> : null
            }
        </div>
    );
}

export default ConnectionSetup;