import ReactDOM from 'react-dom/client'
import App from './app/App.tsx'
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithNavigate from './app/components/authentication/auth0-provider-with-navigate.tsx';
import { ThemeProvider } from './app/themes/ThemeProvider.tsx';
import { GlobalStyle } from './app/components/styled-components/StyledComponents.tsx';
import Background from './app/components/generic/Background.tsx';
import HighlightProvider  from './app/components/highlight/HighlightProvider.tsx';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-VWWZZ02MZM');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <HighlightProvider>
      <Auth0ProviderWithNavigate>
        <ThemeProvider >
          <GlobalStyle />
          <Background>
            <App />
          </Background>
        </ThemeProvider>
      </Auth0ProviderWithNavigate>
    </HighlightProvider>
  </BrowserRouter>
)