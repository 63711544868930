import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StyledTextField } from "../..";
import { useTheme } from "../../../../themes/ThemeProvider";
import { isDomainStringValid } from "../../../../helpers/functions";
import { Grid } from "@mui/material";
import { StyledText } from "../../../text";

interface ChildComponentProps {
    domain: string;
    setDomain: Dispatch<SetStateAction<string>>;
}

const DomainEntry: React.FC<ChildComponentProps> = ({ domain, setDomain }) => {
    const [errorMessage, setErrorMessage] = useState("")
    const [enableLogin, setEnableLogin] = useState(false)
    const [enableError, setEnableError] = useState(false)
    const { config, setConfig } = useTheme();

    useEffect(() => {
        if (config != null) {
            onChange(config?.name)
            setEnableLogin(!config?.loggedIn)
        } else {
            console.log("No config found in state")
        }
    }, []);

    const onChange = (newDomain: string) => {
        setDomain(newDomain);
        if (isDomainStringValid(newDomain)) {
            setEnableLogin(true);
            setEnableError(false)
        } else {
            setEnableLogin(false);
            if (newDomain != "") {
                setEnableError(true);
                setErrorMessage("Invalid domain name");
            } else {
                setEnableError(false);
            }
        }
    }

    return (
        <Grid container >
            <Grid item xs={3} md={3}>
                <StyledText textAlign={'right'} marginTop={25} secondaryText>{"https://"}</StyledText>
            </Grid>
            <Grid item xs={6} md={6}>
                <StyledTextField
                    disabled={config?.loggedIn ? config?.loggedIn : false}
                    value={domain}
                    error={enableError}
                    id="standard-error-helper-text"
                    helperText={errorMessage}
                    variant="filled"
                    label={"Zendesk Domain:"}
                    onChange={(event: { target: { value: string; }; }) => onChange(event.target.value)}
                    width={"100%"}
                />
            </Grid>
            <Grid item xs={3} md={3}>
                <StyledText marginLeft={10} marginTop={25} secondaryText>{".zendesk.com"}</StyledText>
            </Grid>
        </Grid>
    );
}

export default DomainEntry;