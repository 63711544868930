import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "../../components/page-loader";
import { Form } from "../../components/forms";
import CenteredContent from "../../components/generic/CenteredContent";
import { Navigate } from "react-router-dom";
import { TopNavbar } from "../../components/navigation";
import { ConfigurationForm } from "../../components/forms";
import Alert from '@mui/material/Alert';

const ConfigurationPage = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <PageLoader />
    }

    if(!isAuthenticated ) {
        return <Navigate replace to="/login" />
    }

    return (
        <>
            <TopNavbar menu={false} title={"Configuration"} />
            <CenteredContent height={"90vh"}>
                <Form width={900}>            
                    <Alert variant="filled" severity="info">14 day free trial, no credit card required, cancel anytime!</Alert>
                    <br/>
                    <ConfigurationForm/>
                </Form>
            </CenteredContent>
        </>
    );
}

export default ConfigurationPage;