interface FetchDataOptions {
    url: string;
    method: 'GET' | 'POST' | 'PUT';
    // title?: string;
    // userId?: string;
    token: string;
    // body?: object;
    domain?: string;
    code?: string;
    body?: any
};

interface GetOptions {
    url: string;
    // title?: string;
    // userId?: string;
    token: string;
    // body?: object;
    domain?: string | undefined;
    code?: string | undefined;
    body?: object | undefined;
};


const fetchData = async (options: FetchDataOptions) => {
    const {
        url,
        method,
        domain,
        token,
        code,
        body
    } = options;

    if (method === 'POST') {
        const response = await postRequest(url, domain, token, body);
        return response;
    } else if (method === 'GET') {
        const response = await getRequest({ url, domain, token, code, body });
        return response;
    }
};

const getRequest = async (options: GetOptions) => {
    const {
        url,
        domain,
        token,
        code,
        body
    } = options;

    try {
        const urlWithParams = new URL(url);

        let queryParams = {};
        if (body) {
            queryParams = body
        }
        else if (!domain && !code) {

        } else if (!domain) {
            queryParams = {
                code: code
            };
        } else if (!code) {
            queryParams = {
                domain: domain,
            };
        } else {
            queryParams = {
                domain: domain,
                code: code
            };
        }  // dan here

        Object.keys(queryParams).length > 0 ? Object.keys(queryParams).forEach(key => urlWithParams.searchParams.append(key, queryParams[key])) : null;

        const response = await fetch(urlWithParams, {
            method: "GET",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            return response;
        })

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (response.status === 200) {
            const data = await response.json();
            data['status'] = response.status;
            return data;
        }
        const data = await response.json();
        data['status'] = response.status;
        return data;

    } catch (error) {

        // console.error('Error:', "Something bad happened");
        return {error: error, redirect:true}; // or throw the error depending on your use case
    }
}

const postRequest = async (url: string, domain: string | undefined, token: string, body: any) => {

    try {
        if (body) { body["domain"] = domain } else {
            body = { domain: domain }
        }

        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const data = await response.json();
            data['status'] = response.status;
            return data;
        }

        if (response.status === 200) {
            const data = await response.json();
            data['status'] = response.status;
            return data;
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            data['status'] = response.status;
            return data;
        } else {
            return { status: response.status, text: response.statusText };
        }
    } catch (error) {
        console.error('Error:', error);
        return error; // or throw the error depending on your use case  
    }
};

export default fetchData;