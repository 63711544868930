import { Theme, useTheme as useMaterialUITheme } from '@mui/material/styles';
import { OutlinedInput, MenuItem, FormControl, Checkbox } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '../../themes/ThemeProvider';
import { useState } from 'react';

const allLabel = "All"

const getStyles = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DashboardMultiselect = (props: any) => {
  const { onFocus, fields, label, id, onChange, disabled, loadingFilters} = props;
  const theme = useMaterialUITheme();
  const { currentTheme } = useTheme();
  const [personName, setPersonName] = useState<string[]>([]);

  const style = {
    backgroundColor: currentTheme.surface,
    color: currentTheme.onSurface,
    height: '45px'
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 175,
        color: currentTheme.onSurface,
        backgroundColor: currentTheme.surface,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    if(loadingFilters) return;
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const updateChart = () => {
    if(loadingFilters) return;
    // console.log('updateChart')
    onChange(personName)
  }

  const handleSelectAll = () => {
    if (personName.includes(allLabel)) {
      // If allLabel is already selected, deselect all
      setPersonName([]);
    } else {
      // Otherwise, select all fields
      setPersonName([...fields]);
    }
  };

  return (
    <FormControl sx={{  width: 200, mt: 1 }} disabled={disabled}>
      <Select
        id={id}
        multiple
        displayEmpty
        value={personName}
        onFocus={onFocus}
        onChange={handleChange}
        onClose={updateChart}
        input={<OutlinedInput />}
        style={style}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{label}</em>;
          }

          return selected.join(', ');
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {/* <MenuItem value={allLabel} onClick={handleSelectAll} style={getStyles(allLabel, personName, theme)}>
          <Checkbox sx={{ color: currentTheme.onSurface, '&.Mui-checked': { color: currentTheme.primary } }}
            checked={personName.indexOf(allLabel) > -1}
          />
          <em>{allLabel}</em>
        </MenuItem> */}
        {fields.map((field: string) => (

          <MenuItem
            key={field+ Math.random()}
            value={field}
            style={getStyles(field, personName, theme)}
          >
            <Checkbox
              sx={{
                color: currentTheme.onSurface,
                '&.Mui-checked': { color: currentTheme.primary }
              }}
              checked={personName.indexOf(field) > -1}
            />
            {field}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DashboardMultiselect;