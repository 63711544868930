import React from 'react';
import Chip from '@mui/material/Chip';
import { useTheme } from '../../themes/ThemeProvider';

const ChipIcon = ({ weight, icon, label }) => {
    const { currentTheme } = useTheme();
    let color;
    if(label === 'Active') {
        color = currentTheme.primary;
    } else if(label === 'Blocked') {        
        color = 'darkred';
    } else if(label === 'Pending') {
        color = currentTheme.tertiary;
    }

    return (
        <Chip
            icon={icon}
            label={label}
            size="small"
            variant="outlined"
            sx={{ fontWeight: weight === 'fill' ? 'bold' : 'normal', background: color, color: currentTheme.onPrimary}}
        />
    );
};

export default ChipIcon;