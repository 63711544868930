import { Paper, StepContent, StepLabel, Step, Stepper, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '../../../themes/ThemeProvider';
import { StyledText } from '../../text';
import { StyledButton } from '../../buttons';
import CompanySetup from './CompanyDetails/CompanySetup';
import InviteUsers from './InviteUsers/InviteUsers';
import CompleteSetup from './CompleteSetup/CompleteSetup';
import { getConfigurationFromLocalStorage } from '../../../helpers/functions';
import { useNavigate } from 'react-router-dom';
import ConnectionSetup from './ConnectionSetup/ConnectionSetup';

const steps = [
    {
        label: 'Set up Data Connection',
        description: `To see your data show up in the Unevu Dashboard, we require a secure data connection to a supported data source. Choose your data source below to get started:`,
        //     Enter your Zendesk Domain below, then click the link and log in to your Zendesk domain
        // to configure your Zendesk account.`, 
        // Should we add a label here? Example: Info - Why do we need to do this? This is to enable your OAuth credentials, so we can display your data on your dashboard
    },
    {
        label: 'Add Company Details',
        description: `Data connected! We are working in the background to provide your data. In the meantime, can you provide us with some information about yourself so we can better serve you?`,
    },
    {
        label: 'Invite Coworkers',
        description: `Thanks for letting us know all this information, it helps us to better tailor our customer support to your needs. Is there anyone you would like to invite to the team? We can also set their permissions in this step.`,
    },
    {
        label: 'Browse Data',
        description: `Thank you for your patience, your dashboard should be set up with a preview of your data. We will work hard to get you your entire ticket history as soon as possible. `,
    },
];

const defaultUsers = [
    { firstName: "", lastName: "", title: "", email: "", admin: false, errorStatus: { firstName: false, lastName: false, title: false, email: false } },
    { firstName: "", lastName: "", title: "", email: "", admin: false, errorStatus: { firstName: false, lastName: false, title: false, email: false } },
    { firstName: "", lastName: "", title: "", email: "", admin: false, errorStatus: { firstName: false, lastName: false, title: false, email: false } }
]

const ConfigurationForm = () => {
    const { currentTheme, config } = useTheme();
    const [domain, setDomain] = useState(config?.name);
    const [apikey, setApikey] = useState("");
    const [apikeyEmail, setApikeyEmail] = useState("");
    const [firstName, setFirstName] = useState(config?.user?.firstName);
    const [lastName, setLastName] = useState(config?.user?.lastName);
    const [title, setTitle] = useState(config?.user?.title);
    const [email, setEmail] = useState(config?.user?.email);
    const [organizationName, setOrganizationName] = useState(config?.organization?.name);
    const [organizationSize, setOrganizationSize] = useState(config?.organization?.size);
    const [usersToInvite, setUsersToInvite] = useState(defaultUsers);
    const navigate = useNavigate();

    const currentActiveStep = JSON.parse(JSON.stringify(localStorage.getItem("activeStep")));
    let currentActiveStepNumber: number = Number(currentActiveStep);

    const [activeStep, setActiveStep] = useState(currentActiveStepNumber ? currentActiveStepNumber : 0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePrevious = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const goToDashboard = () => {
        // setActiveStep(0)
        navigate("/dashboard")
        // TO DO: Fix this so we access the user from auth0
        window.location.reload();
    };

    const loadConfigIntoForm = () => {
        if (config != null) {
            setDomain(config?.name)
            setFirstName(config?.user?.firstName)
            setLastName(config?.user?.lastName)
            setTitle(config?.user?.title)
            setEmail(config?.user?.email)
            setOrganizationName(config?.organization?.name)
            setOrganizationSize(config?.organization?.size)
        }
    }

    useEffect(() => {
        localStorage.setItem("activeStep", JSON.stringify(activeStep))
    }, [activeStep])

    useEffect(() => {
        if (config?.name === "") {
            const localStorageConfig = getConfigurationFromLocalStorage();
            // TODO: Fix this, prevent it from rerendering the form
            // if (localStorageConfig) { 
            //     setConfig(localStorageConfig) 
            // }
        }
        loadConfigIntoForm();
    }, [config]);

    return (
        <Box sx={{ maxWidth: 900 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === steps.length - 1 ? (
                                    <StyledText secondaryText>{"Last step"}</StyledText>
                                ) : null
                            }
                        >
                            <StyledText>
                                {step.label}
                            </StyledText>
                        </StepLabel>

                        <StepContent>
                            <StyledText secondaryText>{step.description}</StyledText>
                            <Box sx={{ mb: 2 }}>
                                {index === 0 ? <ConnectionSetup
                                    handleNext={handleNext}
                                    domain={domain}
                                    setDomain={setDomain}
                                    apikey={apikey}
                                    setApikey={setApikey}
                                    apikeyEmail={apikeyEmail}
                                    setApikeyEmail={setApikeyEmail}
                                /> : null}
                                {index === 1 ? <CompanySetup
                                    domain={domain}
                                    handleNext={handleNext}
                                    handlePrevious={handlePrevious}
                                    firstName={firstName} setFirstName={setFirstName}
                                    lastName={lastName} setLastName={setLastName}
                                    title={title} setTitle={setTitle}
                                    email={email} setEmail={setEmail}
                                    organizationName={organizationName} setOrganizationName={setOrganizationName}
                                    organizationSize={organizationSize} setOrganizationSize={setOrganizationSize}
                                /> : null}
                                {index === 2 ?
                                    <div>
                                        <br />
                                        <InviteUsers
                                            defaultUsers={defaultUsers}
                                            usersToInvite={usersToInvite}
                                            setUsersToInvite={setUsersToInvite}
                                            handleNext={handleNext}
                                            handlePrevious={handlePrevious}
                                            domain={domain}
                                        />
                                    </div> : null}
                                {index === 3 ? <CompleteSetup handleNext={handleNext} handlePrevious={handlePrevious} /> : null}
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ backgroundColor: currentTheme.raised, p: 3 }}>
                    <StyledText>All steps completed - you&apos;re finished!</StyledText>
                    <StyledButton
                        onClick={goToDashboard}
                        enabled={true}
                        text={"Go to Dashboard"}
                    >
                    </StyledButton>
                </Paper>
            )}
        </Box>
    );
}

export default ConfigurationForm