import { AppBar, Box, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../themes/ThemeProvider';
import SideDrawer from './SideDrawer';
import { useState } from 'react';
import ProfileDropdown from './ProfileDropdown';

const TopNavbar = (props: any) => {
    const [openDrawer, setDrawerOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openProfile = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { title, menu } = props;
    const { user, isAuthenticated } = useAuth0();
    const { currentTheme } = useTheme();
    const style = {
        backgroundColor: currentTheme.surface
    }

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };

    return (
        isAuthenticated && user ? (
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="static"
                    sx={style}
                >
                    <Toolbar>
                        {menu ? <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={
                                {
                                    mr: 2,
                                    color: currentTheme.onSurface
                                }
                            }
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                            <Box
                                component="img"
                                sx={{
                                    height: 43,
                                    width: 152,
                                    scale: "60%"
                                }}
                                alt="unevu logo"
                                src="https://unevu.com/icons/unevu-big-trans-bkg.png"
                            />
                        </IconButton> : null}
                        <Typography
                            variant="h5"
                            sx={{
                                color: currentTheme.onSurface,
                                flexGrow: 1,
                                textAlign: "center",
                            }}
                        >
                            {title}
                        </Typography>
                        <Button color="inherit" onClick={handleClick}>
                            <img src={user.picture} alt={user.name || ''} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        </Button>

                        <ProfileDropdown open={openProfile} anchorEl={anchorEl} handleClose={handleClose} />
                        <SideDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
                    </Toolbar>
                </AppBar>
            </Box>
        ) : null
    );
}

export default TopNavbar;