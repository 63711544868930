import { Route, Routes, Navigate } from 'react-router-dom'
import { LoginPage, Dashboard, BillingPage, CallbackPage, DemoDashboard, ConfigurationPage, OauthCallbackPage, BillingReturnPage } from './pages'
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from './themes/ThemeProvider';
import { PageLoader } from './components/page-loader';
import AuthenticationGuard from './components/authentication/AuthenticationGuard';
import AdministrationPage from './pages/Admin/AdministrationPage';
import { useEffect, useState } from 'react';
import fetchData from './helpers/requests';

const App = () => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { config, currentTheme, userRole, setUserRole } = useTheme();
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [allowRedirect, setAllowRedirect] = useState(false);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const token = await getAccessTokenSilently();
        // TODO: Implement getUserRole function in all areas of app
        const role = await getUserRole(config?.name, token);
        setUserRole(role); // Cast 'role' to 'SetStateAction<UserRole>'
        setLoadingConfig(false);
      } catch (error) {
        console.log(error);
      }
    };
    // TODO: Only do this if the user is logged in
    fetchUserRole();
  }, [config]);


  useEffect(() => {
    if (!loadingConfig) {
      setAllowRedirect(true);
    }
  }, [loadingConfig]);

  if (isLoading) {
    return <PageLoader />
  }

  // TODO: fix this dirty hack to prevent unauthorized users from accessing certain pages
  return (
    <div style={{ backgroundColor: currentTheme.background }}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/demo" element={<DemoDashboard />} />
        <Route path="/configuration" element={< AuthenticationGuard component={ConfigurationPage} />} />
        <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard} />} />
        {!allowRedirect || userRole === "admin" || userRole === undefined? <Route path="/billing" element={<AuthenticationGuard component={BillingPage} />} /> : <Route path="/billing" element={<Navigate to="/dashboard" />} />}
        {!allowRedirect || userRole === "admin" || userRole === undefined ? <Route path="/checkout" element={<AuthenticationGuard component={BillingReturnPage} />} /> : <Route path="/checkout" element={<Navigate to="/dashboard" />} />}
        {!allowRedirect || userRole === "admin" || userRole === undefined ? <Route path="/admin" element={<AuthenticationGuard component={AdministrationPage} />} /> : <Route path="/admin" element={<Navigate to="/dashboard" />} />}
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="/zendesk/oauth/callback" element={<OauthCallbackPage />} />
      </Routes>
    </div>
  )
}

export default App


// TODO: Go through together to ensure RBAC will work properly
const getUserRole = async (domain: string, token: string) => {
  const userRole = await fetchData({
    url: import.meta.env.VITE_UNEVU_API_URL + "/user/role", // /user?email='<string>' once endpoint is ready
    method: "GET",
    domain: domain,
    token: token
  })
  return userRole.data;
}