import React, { useEffect, useState } from 'react';
import ChartsEmbedSDK, { Dashboard } from '@mongodb-js/charts-embed-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../themes/ThemeProvider';
import { DashboardMultiselect } from '../buttons';
import { objectsEqual } from '../../helpers/functions';
import MyDatePicker from '../styled-components/DatePicker';
import { StyledText } from '../text';

interface MongoDBChartProps {
  dashboardId: string;
  chartsProjectId: string;
  width?: string;
  height?: string;
  authenticated?: boolean;
}

interface Document {
  value: number,
  x: string,
  y: string
}
interface ChartData {
  documents: Document[];  // Assuming Document is the correct type for each item in the 'documents' array
  // Add other properties as needed
}

const MongoDBDashboard: React.FC<MongoDBChartProps> = ({
  dashboardId,
  chartsProjectId,
  width,
  height,
  authenticated
}) => {
  let dashboard: Dashboard;

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate.getTime() - (14 * 24 * 60 * 60 * 1000));

  let dateFilter = { "created_at": { "$gt": defaultStartDate, "$lt": defaultEndDate } }

  const { getAccessTokenSilently } = useAuth0();
  const { theme, currentTheme, config } = useTheme();
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard>();
  const [defaultFilters, setDefaultFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(false);

  const unauthenticatedChartSettings = {
    baseUrl: chartsProjectId,
    dashboardId: dashboardId,
    // width: width,
    // height: height,
    widthMode: "scale",
    heightMode: "scale",
    showAttribution: false,
    theme: theme,
    background: currentTheme.background,
    chartsBackground: currentTheme.surface,
    filter: dateFilter
  }

  const authenticatedChartSettings = {
    ...unauthenticatedChartSettings,
    getUserToken: async () => await getTokenInfo()
  }

  const getTokenInfo = async () => {
    const token = await getAccessTokenSilently();
    return token;
  }

  const changeDateFilterFunction = async (value: any) => {

    let filters: { [key: string]: any } = { ...currentFilters };
    let dateFilter = { "$gt": new Date(value[0]), "$lt": new Date(value[1]) }
    filters["created_at"] = dateFilter;
    if (!objectsEqual(filters, currentFilters)) {
      await currentDashboard?.setFilter(filters);
      setCurrentFilters(filters);
    }
  }

  const changeDashboardFilter = async (array: string[], field: string) => {
    if(loadingFilters) return;

    let filters: { [key: string]: any } = { ...currentFilters };

    filters[field] = array.length === 0 ? { $in: (defaultFilters as any)[field] } : { $in: array };

    if (currentDashboard) {
      if (!objectsEqual(filters, currentFilters)) {
        await currentDashboard.setFilter(filters);
        setCurrentFilters(filters);
      }
    } else {
      console.error('Current Dashboard is undefined.');
    }
  }

  useEffect(() => {
    getTokenInfo();
    const sdk = new ChartsEmbedSDK(authenticated ? authenticatedChartSettings : unauthenticatedChartSettings);

    dashboard = sdk.createDashboard({ dashboardId: dashboardId });

    setCurrentDashboard(dashboard)

    const dashboardContainer = document.getElementById('dashboard');

    if (dashboardContainer) {
      renderDashboard(dashboard, dashboardContainer, setLoadingDashboard)
    }
  }, []);

  const requestData = async (dashboard?: Dashboard): Promise<void> => {
    if (Object.keys(defaultFilters).length === 0) {
      setLoadingFilters(true);
      let dashboardInfo = await currentDashboard?.getAllCharts() as any;

      if (dashboard && dashboardInfo.length > 0) {
        const statusInfo = await dashboard?.getChart(dashboardInfo[2].chartId);
        const nameInfo = await dashboard?.getChart(dashboardInfo[6].chartId);

        const statusData: ChartData = (await statusInfo.getData()) as ChartData;
        const nameData: ChartData = (await nameInfo.getData()) as ChartData;

        const statusFilter = await buildDefaultFilter(statusData, "status", "color")
        const nameFilter = await buildDefaultFilter(nameData, "lookupFields.assigneeName", "y")

        if (statusFilter?.length === 0 && nameFilter?.length === 0) {
          setCurrentFilters({})
        } else {
          let newDefaultFilters = {
            "status": statusFilter,
            "lookupFields.assigneeName": nameFilter
          }

          if (newDefaultFilters != defaultFilters) {
            setDefaultFilters(newDefaultFilters);
            setLoadingFilters(false);
          }
        }

      } else {
        console.error('No charts found in the dashboard.');
      }
    }
  }

  const buildDefaultFilter = (
    data: ChartData,
    field: string, key: string
  ) => {
    if (data && data.documents) {
      const documents: Document[] = data.documents;
      const convertedData = convertChartDataToStrings(documents, key);
      return convertedData;
    }
  };

  const addEventListenersToCharts = async () => {
    const charts = await currentDashboard?.getAllCharts();
    if (charts) {
      charts.forEach((chart: any) => {
        chart.addEventListener('click', (event: any) => {
          setHighlight(chart, event.selectionFilter);

          currentDashboard?.setFilter(event.selectionFilter);
        });
      });
    }
  }

  const renderDashboard = async (dashboard: Dashboard, dashboardContainer: HTMLElement, setLoadingDashboard: (loading: boolean) => void) => {
    await dashboard.render(dashboardContainer).catch(() => console.log('Dashboard failed to initialize'))
    setLoadingDashboard(false);
  }

  const setHighlight = (chart: any, selectionFilter: any) => {
    chart.setHighlight(selectionFilter);
  }

  useEffect(() => {
    if (!loadingDashboard) {
      addEventListenersToCharts();
    }
  }, [loadingDashboard]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%"
        }}>

        <div style={{
          marginLeft: "34px",
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px"
        }}>
          <MyDatePicker changeDateFilterFunction={changeDateFilterFunction} defaultDate={[defaultStartDate, defaultEndDate]} />
          <StyledText secondaryText variant={'body3'} marginTop={17}>
            {config?.lastDataPull ? `Last Updated on: ${new Date(config?.lastDataPull).toLocaleString()}` : ""}
          </StyledText>
        </div>
        <div style={{
          marginRight: "34px",
          marginLeft: "34px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px"
        }}>
          <DashboardMultiselect
          loadingFilters={loadingFilters}
            disabled={loadingDashboard}
            id={"status-filter"}
            onFocus={() => { requestData(currentDashboard); }}
            onChange={(value: string[]) => changeDashboardFilter(value, "status")}
            fields={(defaultFilters as { [key: string]: any })["status"] || []}
            label={"Status"}
          />
          <DashboardMultiselect
          loadingFilters={loadingFilters}
            disabled={loadingDashboard}
            id={"name-filter"}
            onFocus={() => { requestData(currentDashboard); }}
            onChange={(value: string[]) => changeDashboardFilter(value, "lookupFields.assigneeName")}
            fields={(defaultFilters as { [key: string]: any })["lookupFields.assigneeName"] || []}
            label={"Assignees"}
          />
        </div>
      </div>
      <div style={{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "10px"
      }}>
        <div style={{ "width": "100vw", "height": "85vh" }} id="dashboard"></div>
      </div>
    </div>
  );
};

interface Document {
  [key: string]: any;
}

const convertChartDataToStrings = (docs: Document[], key: string) => {
  let arrayOfStrings: string[] = [];

  for (let i = 0; i < docs.length; i++) {
    if (!arrayOfStrings.includes(docs[i][key])) {
      arrayOfStrings.push(docs[i][key])
    }
  }

  return arrayOfStrings;
}

export default MongoDBDashboard;