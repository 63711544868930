'use client';
import { useState, useEffect, useCallback } from 'react';
import { Chip, Button, Divider, FormControl, OutlinedInput, Stack, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../themes/ThemeProvider';
import StyledSelect from '../../components/forms/StyledSelect';
import StyledText from '../../components/text/StyledText';
import fetchData from '../../helpers/requests';

import { FilterButton, FilterPopover, useFilterContext } from './components/filter-button';
import { useAuth0 } from '@auth0/auth0-react';

import { useCustomersSelection } from './customers-selection-context';

// The tabs should be generated using API data.
export function CustomersFilters({ filters = {}, sortDir = 'desc', sortedUsers, setUserDeleted, setUserDeletedError, triggerFunction}) {
  const { email, phone, status } = filters;
  const { getAccessTokenSilently } = useAuth0();
  const [sortDirection, setSortDir] = useState(sortDir);
  const navigate = useNavigate();
  const selection = useCustomersSelection();
  const { config, currentTheme } = useTheme();

  let activeUsers = 0;
  let pendingUsers = 0;

  for (let i = 0; i < sortedUsers.length; i++) {
    if (sortedUsers[i].status === 'active') {
      activeUsers++;
    }
    if (sortedUsers[i].status === 'pending') {
      pendingUsers++;
    }
  }

  const tabs = [
    { label: 'All', value: '', count: sortedUsers?.length },
    { label: 'Active', value: 'active', count: activeUsers },
    { label: 'Pending Invite', value: 'pending', count: pendingUsers },
    // { label: 'Disabled', value: 'blocked', count: 1 },
  ];

  const updateSearchParams = useCallback(
    (newFilters, newSortDir) => {
      const searchParams = new URLSearchParams();

      if (newSortDir === 'asc') {
        searchParams.set('sortDir', newSortDir);
      }

      if (newFilters.status) {
        searchParams.set('status', newFilters.status);
      }

      if (newFilters.email) {
        searchParams.set('email', newFilters.email);
      }

      if (newFilters.phone) {
        searchParams.set('phone', newFilters.phone);
      }

      navigate(`/admin?${searchParams.toString()}`);
    },
    [navigate]
  );

  const handleClearFilters = useCallback(() => {
    updateSearchParams({}, sortDir);
  }, [updateSearchParams, sortDir]);

  const handleStatusChange = useCallback(
    (_, value) => {
      updateSearchParams({ ...filters, status: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir]
  );

  const handleEmailChange = useCallback(
    (value) => {
      updateSearchParams({ ...filters, email: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir]
  );

  const handlePhoneChange = useCallback(
    (value) => {
      updateSearchParams({ ...filters, phone: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir]
  );

  const handleSortChange = useCallback(
    (event) => {
      updateSearchParams(filters, event);
    },
    [updateSearchParams, filters]
  );

  const hasFilters = status || email || phone;
  
  const deleteUsers = async () => {
    let selectedUsers = [];
    selection.selected.forEach((user) => {
      selectedUsers.push(user);
    });

    const token = await getAccessTokenSilently();

    const result = await fetchData({
      url: import.meta.env.VITE_UNEVU_API_URL + "/user/delete-users",
      method: "POST",
      domain: config?.name,
      token: token,
      code: 'null',
      body: { users: selectedUsers }
    });

    if(result.status === 200) { 
      setUserDeletedError(false);
      setUserDeleted(true);
    } else {
      setUserDeleted(false);
      setUserDeletedError(true);
    }
    triggerFunction();
  }

  return (
    <div>
      <Tabs onChange={handleStatusChange} sx={{ px: 3 }} value={status ?? ''} variant="scrollable">
        {tabs.map((tab) => (
          <Tab
            icon={<Chip sx={{ color: currentTheme.primary, background: currentTheme.surface }} label={tab.count} size="small" variant="soft" />}
            iconPosition="end"
            key={tab.value}
            label={tab.label}
            sx={{
              minHeight: 'auto',
              color: currentTheme.primary,
              '&.Mui-selected': { color: currentTheme.secondary, fontWeight: "bold" },
              '&.selected': {
                color: 'red',
                fontWeight: "bold",
              },
            }}
            tabIndex={0}
            value={tab.value}
            indicator={currentTheme.primary}
          />
        ))}
      </Tabs>
      <Divider sx={{ background: currentTheme.primary }} />
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', flexWrap: 'wrap', px: 3, py: 2 }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', flex: '1 1 auto', flexWrap: 'wrap' }}>
          <FilterButton
            displayValue={email}
            label="Email"
            onFilterApply={(value) => {
              handleEmailChange(value);
            }}
            onFilterDelete={() => {
              handleEmailChange();
            }}
            popover={<EmailFilterPopover />}
            value={email}
          />
          <FilterButton
            displayValue={phone}
            label="Phone Number"
            onFilterApply={(value) => {
              handlePhoneChange(value);
            }}
            onFilterDelete={() => {
              handlePhoneChange();
            }}
            popover={<PhoneFilterPopover />}
            value={phone}
          />
          {hasFilters ? <Button sx={{ color: currentTheme.primary }} onClick={handleClearFilters}>Clear filters</Button> : null}
        </Stack>
        {selection.selectedAny ? (
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <StyledText color="text.secondary" variant="body2">
              {selection.selected.size} selected
            </StyledText>
            <Button color="error" variant="contained" onClick={() => deleteUsers()}>
              Delete
            </Button>
          </Stack>
        ) : null}

        <StyledSelect
          title={"Sort Direction"}
          label={"Sort Direction"}
          value={sortDirection}
          setValue={setSortDir}
          function={handleSortChange}
          selectValues={
            [
              { label: 'Newest', value: 'desc' },
              { label: 'Oldest', value: 'asc' },
              // { label: 'Now', value: 'now' },
              // { label: 'Today', value: 'today' },
              // { label: 'Yesterday', value: 'yesterday' },
              // { label: 'This week', value: 'this-week' },
              // { label: 'Last week', value: 'last-week' },
              // { label: 'This month', value: 'this-month' },
              // { label: 'Last month', value: 'last-month' },
              // { label: 'Custom', value: 'custom' },
            ]
          }

        />

      </Stack>
    </div>
  );
}

function EmailFilterPopover() {
  const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
  const [value, setValue] = useState('');
  const { currentTheme } = useTheme();

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  return (
    <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title="Filter by email">
      <FormControl>
        <OutlinedInput
          sx={{ background: currentTheme.raised, color: currentTheme.onRaised }}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onApply(value);
            }
          }}
          value={value}
        />
      </FormControl>
      <Button
        sx={{ background: currentTheme.primary, color: currentTheme.onPrimary, '&:hover': { background: currentTheme.secondary, color: currentTheme.onSecondary } }}
        onClick={() => {
          onApply(value);
        }}
        variant="contained"
      >
        Apply
      </Button>
    </FilterPopover>
  );
}

function PhoneFilterPopover() {
  const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
  const [value, setValue] = useState('');
  const { currentTheme } = useTheme();

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  return (
    <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title="Filter by phone number">
      <FormControl>
        <OutlinedInput
          sx={{ background: currentTheme.raised, color: currentTheme.onRaised }}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onApply(value);
            }
          }}
          value={value}
        />
      </FormControl>
      <Button
        sx={{ background: currentTheme.primary, color: currentTheme.onPrimary, '&:hover': { background: currentTheme.secondary, color: currentTheme.onSecondary } }}
        onClick={() => {
          onApply(value);
        }}
        variant="contained"
      >
        Apply
      </Button>
    </FilterPopover>
  );
}
