import { useLocation, useNavigate } from "react-router-dom";
import { TopNavbar } from "../../components/navigation";
import fetchData from "../../helpers/requests";
import { useAuth0 } from "@auth0/auth0-react";
import { SetStateAction, useEffect, useState } from "react";
import CenteredContent from "../../components/generic/CenteredContent";
import { Form } from "../../components/forms";
import { StyledText } from "../../components/text";

import { useTheme } from "../../themes/ThemeProvider";
import { Config } from "../../helpers/types";

const OauthCallbackPage: React.FC = () => {
  const { config, setConfig } = useTheme();
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  let domain: string = getDomainFromLocalStorage() as string;
  const code: string = getCodeFromURL() as string;

  const handleCallback = async () => {
    const token = await getAccessTokenSilently();

    try {
      let result = {
        status: 0
      };

      if (!domain) {
        domain = config.name;
      }

      const domainSent = await sendDomainToDatabase(token, domain);

      if (domainSent.status === 200) {
        result = await sendCode(token, code, domain)
        await updateConfigInDatabase(token, domain, getConfigFromLocalStorage()) // TODO: Use state config properly
      }

      if (result) {

        if (result.status === 200) {
          setTimeout(() => {
            // add message
            setShowMessage(true)
            setMessage("Redirecting...")
            navigate("/configuration")
          }, 3000);
          setShowMessage(true)
          setMessage("Zendesk Connection linked! We're going to redirect you in 3 seconds")

          updateConfigState(getConfigFromLocalStorage(), setConfig);
        } else {
          // add error message
          setShowMessage(true)
          setMessage("Zendesk Connection failed! We're going to redirect you in 3 seconds so you can try again")
          // TODO: change error message depending on the error
          setTimeout(() => {
            setShowMessage(true)
            setMessage("Redirecting...")
            navigate("/configuration")
          }, 3000);
        }
        // Handle other conditions based on the result
      } else {
        setShowMessage(true)
        setMessage("It didnt work due to a data issue! We're going to redirect you in 3 seconds")
        setTimeout(() => {
          setShowMessage(true)
          setMessage("Redirecting...")
          navigate("/configuration")
        }, 3000);
        // add error message
      }
    } catch (error) {
      setTimeout(() => {
        setShowMessage(true)
        setMessage("Redirecting...")
        navigate("/configuration")
      }, 3000);
      setShowMessage(true)
      setMessage("It had an error during call back ! We're going to redirect you in 5 seconds")
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);


  return (
    <div>
      <TopNavbar menu={false} />
      <CenteredContent height={"90vh"}>
        {showMessage ? <Form width={900}>
          <StyledText secondaryText textAlign={"center"}>
            {message}
          </StyledText>
        </Form> : null}
      </CenteredContent>
    </div>
  );
};

export default OauthCallbackPage;

const getCodeFromURL = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  return code;
}

const getDomainFromLocalStorage = () => {
  const config: string = localStorage.getItem("config") as string;
  const parsedConfig = JSON.parse(config)
  const domain = parsedConfig?.name;

  return domain;
}

const getConfigFromLocalStorage = () => {
  const config: string = localStorage.getItem("config") as string;
  const parsedConfig = JSON.parse(config)
  return parsedConfig;
}

const sendDomainToDatabase = async (token: string, domain: string) => {
  const result = await fetchData({
    url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config",
    method: "POST",
    domain: domain,
    token: token,
    code: 'null'
  });

  return result
}

const sendCode = async (token: string, code: string, domain: string) => {

  const result = await fetchData({
    url: `${import.meta.env.VITE_UNEVU_API_URL}/oauth/zendesk/code`,
    method: "GET",
    domain: domain,
    code: code,
    token: token
  })

  return result;
}

const updateConfigInDatabase = async (token: string, domain: string, config: Config) => {
  await fetchData({
    url: `${import.meta.env.VITE_UNEVU_API_URL}/domain/config/update`,
    method: "POST",
    domain: domain,
    code: 'null',
    token: token,
    body: {config:{ addZendeskConfig: config }}
  })
}

const updateConfigState = (config: Config, setConfig: { (value: SetStateAction<Config>): void; }) => {
  let newConfig = config;

  newConfig["loggedIn"] = true;
  setConfig(newConfig)
  localStorage.setItem("config", JSON.stringify(newConfig))
}