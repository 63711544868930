import { useTheme } from "../../themes/ThemeProvider";

const Background = (props: any) => {
    const { currentTheme } = useTheme();

    // TODO: Fix the height of the background
    const style = {
        height: "110vh",
        backgroundColor: currentTheme.background
    }

    return (
        <div style={style}>
            {props.children}
        </div>
    );
};

export default Background;