import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import StyledButton from "../../components/buttons/StyledButton";
import StyledTitle from "../../components/text/StyledTitle";
import InviteUserModal from "../../components/forms/InviteUserModal";
import EditUserModal from "../../components/forms/EditUserModal";
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { useSearchParams } from 'react-router-dom';

import CustomizedSnackbars from '../../components/forms/UserFeedback/CustomizedSnackbars';
import { CustomersFilters } from './customers-filters';
import { CustomersPagination } from './customers-pagination';
import { CustomersSelectionProvider } from './customers-selection-context';
import { CustomersTable } from './customers-table';
import { useTheme } from '../../themes/ThemeProvider';

const metadata = { title: `List | Customers | Dashboard | NEW NAME` };

const List = ({ rows, refreshPage }) => {
  const { email, phone, sortDir, status } = useExtractSearchParams();
  const { currentTheme } = useTheme();
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [emailsSent, setEmailsSent] = useState(false)
  const [emailsError, setEmailsError] = useState(false)
  const [userUpdated, setUserUpdated] = useState(false)
  const [userError, setUserError] = useState(false)
  const [userDeleted, setUserDeleted] = useState(false)
  const [userDeletedError, setUserDeletedError] = useState(false)
  const [numEmailsSent, setNumEmailsSent] = useState(0)
  const [selectedUser, setSelectedUser] = useState(rows[0]);

  const sortedUsers = applySort(rows, sortDir);
  const filteredUsers = applyFilters(sortedUsers, { email, phone, status });

  const openEditUserModal = (bool, row) => {
    setSelectedUser(row)
    setEditUserModalOpen(bool)
  }

  return (
    <React.Fragment >
      <title>{metadata.title}</title>
      <Box
        sx={{
          maxWidth: 'var(--Content-maxWidth)',
          m: 'var(--Content-margin)',
          p: 'var(--Content-padding)',
          width: 'var(--Content-width)',
        }}
      >
        <Stack spacing={4}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ alignItems: 'flex-start', }}>
            <Box sx={{ flex: '1 1 auto' }}>
              <StyledTitle >Users</StyledTitle>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledButton onClick={() => { setInviteUserModalOpen(true) }} text={"Invite New User"} enabled startIcon={<PlusIcon />}>Add</StyledButton>
            </Box>
          </Stack>
          <CustomersSelectionProvider customers={filteredUsers}>
            <Card sx={{ background: currentTheme.raised, color: currentTheme.onRaised }}>
              <CustomersFilters
                filters={{ email, phone, status }}
                sortedUsers={sortedUsers}
                sortDir={sortDir}
                setUserDeleted={setUserDeleted}
                setUserDeletedError={setUserDeletedError}
                triggerFunction={refreshPage}
              />
              <Divider sx={{ border: "1px solid " + currentTheme.secondary }} />
              <Box sx={{ overflowX: 'auto' }}>
                <CustomersTable rows={filteredUsers} setEditUserModalOpen={openEditUserModal} />
              </Box>
              <Divider />
              <CustomersPagination count={filteredUsers.length} page={0} />
            </Card>
          </CustomersSelectionProvider>
        </Stack>
      </Box>
      <InviteUserModal
        open={inviteUserModalOpen}
        setOpen={setInviteUserModalOpen}
        emailsSent={emailsSent}
        setEmailsSent={setEmailsSent}
        emailsError={emailsError}
        setEmailsError={setEmailsError}
        numEmailsSent={numEmailsSent}
        setNumEmailsSent={setNumEmailsSent}
        triggerFunction={refreshPage}
      ></InviteUserModal>
      <EditUserModal
        open={editUserModalOpen}
        setOpen={setEditUserModalOpen}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        triggerFunction={refreshPage}
        setUserUpdated={setUserUpdated}
        setUserError={setUserError}
      >

      </EditUserModal>

      {emailsSent ? <CustomizedSnackbars resetFunc={setEmailsSent} severity={"success"} message={numEmailsSent + " valid emails detected, notification(s) sent."} /> : null}
      {emailsError ? <CustomizedSnackbars resetFunc={setEmailsError} severity={"error"} message={"There was an error sending your email(s), please confirm that you have filled in all fields correctly."} /> : null}
      {userUpdated ? <CustomizedSnackbars resetFunc={setUserUpdated} severity={"success"} message={"User updated successfully."} /> : null}
      {userError ? <CustomizedSnackbars resetFunc={setUserError} severity={"error"} message={"Unable to update user, please try again."} /> : null}
      {userDeleted ? <CustomizedSnackbars resetFunc={setUserUpdated} severity={"success"} message={"User deleted successfully."} /> : null}
      {userDeletedError ? <CustomizedSnackbars resetFunc={setUserError} severity={"error"} message={"Unable to delete user, please try again."} /> : null}
    </React.Fragment>
  );
}

function useExtractSearchParams() {
  const [searchParams] = useSearchParams();

  return {
    email: searchParams.get('email') || undefined,
    phone: searchParams.get('phone') || undefined,
    sortDir: searchParams.get('sortDir') || undefined,
    status: searchParams.get('status') || undefined,
  };
}

// Sorting and filtering has to be done on the server.

function applySort(row, sortDir) {
  return row.sort((a, b) => {
    if (sortDir === 'asc') {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }

    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
}

function applyFilters(row, { email, phone, status }) {
  return row.filter((item) => {
    if (email) {
      if (!item.email?.toLowerCase().includes(email.toLowerCase())) {
        return false;
      }
    }

    if (phone) {
      if (!item.phone?.toLowerCase().includes(phone.toLowerCase())) {
        return false;
      }
    }

    if (status) {
      if (item.status !== status) {
        return false;
      }
    }

    return true;
  });
}

export default List;