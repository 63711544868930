import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface Auth0ProviderWithNavigateProps {
  children: ReactNode;
}

const Auth0ProviderWithNavigate: React.FC<Auth0ProviderWithNavigateProps> = ({
  children,
}: Auth0ProviderWithNavigateProps) => {
  
  const navigate = useNavigate();

  const DOMAIN : string = import.meta.env.VITE_AUTH0_DOMAIN!;
  const CLIENTID : string = import.meta.env.VITE_AUTH0_CLIENT_ID!;
  const REDIRECT_URI : string = import.meta.env.VITE_AUTH0_CALLBACK_URL!;
  const AUDIENCE : string = import.meta.env.VITE_AUTH0_AUDIENCE!;

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(DOMAIN && CLIENTID && REDIRECT_URI)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENTID}
      authorizationParams={{
        redirect_uri: REDIRECT_URI,
        audience: AUDIENCE
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;