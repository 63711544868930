const CenteredContent = (props: any) => {
    const { height } = props;
    const style = {
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
    
    return (
        <div style={style}>
            {props.children}
        </div>
    );
};

export default CenteredContent;