import { Grid } from "@mui/material";
import { StyledText } from "../../../../text";
import StyledTextField from "../../../StyledTextField";
import { StyledButton } from "../../../../buttons";
import { Dispatch, SetStateAction } from "react";
import { Config } from "../../../../../helpers/types";

interface OAuthCredentialsFormProps {
    config: Config;
    setConfig: Dispatch<SetStateAction<Config>>;
    onChange: (newString: string) => void;
    enableLogin: boolean;
    domain: string;
    enableError: boolean;
    errorMessage: string;
}

const OAuthCredentialsForm = ({
    config,
    setConfig,
    onChange,
    enableLogin,
    domain,
    enableError,
    errorMessage
}: OAuthCredentialsFormProps) => {

    const updateConfig = (config: Config, setConfig: Dispatch<SetStateAction<Config>>) => {
        return new Promise((resolve, reject) => {
            if (config != null) {
                let newConfig = config;
                config['name'] = domain;
                localStorage.setItem("config", JSON.stringify(newConfig));
                setConfig(newConfig);
            }
            resolve(null);
        });
    };

    return (
        <Grid container >
            <Grid item xs={2} md={2}>
                <StyledText textAlign={'right'} marginTop={25} secondaryText >{"https://"}</StyledText>
            </Grid>
            <Grid item xs={6} md={5}>
                <StyledTextField
                    disabled={config?.loggedIn ? config?.loggedIn : false}
                    value={domain}
                    error={enableError}
                    id="standard-error-helper-text"
                    helperText={errorMessage}
                    variant="filled"
                    label={"Zendesk Domain:"}
                    onChange={(event: { target: { value: string; }; }) => onChange(event.target.value)}
                    width={"95%"}
                />
            </Grid>
            <Grid item xs={4} md={2}>
                <StyledText marginTop={25} secondaryText>{".zendesk.com"}</StyledText>
            </Grid>
            <Grid item xs={12} md={3}>
                <StyledButton
                    marginTop={"20px"}
                    onClick={async () => {
                        await updateConfig(config, setConfig);
                        await redirectToOAuth(config?.name);
                    }}
                    text={"Sign in To Zendesk"}
                    enabled={enableLogin}
                />
            </Grid>
        </Grid>
    );
}

export default OAuthCredentialsForm;

const redirectToOAuth = (domain: string) => {
    let redirectURL = new URL(`https://${domain}.zendesk.com/oauth/authorizations/new`)
    redirectURL.searchParams.append("response_type", "code");
    redirectURL.searchParams.append("redirect_uri", `${import.meta.env.VITE_UNEVU_FRONTEND_URL}/zendesk/oauth/callback`);           // TODO: we will need to change this to our prod URL when we deploy
    redirectURL.searchParams.append("client_id", `${import.meta.env.VITE_ZENDESK_CLIENT_ID}`);
    redirectURL.searchParams.append("scope", "users:read hc:read read webhooks:write webhooks:read");
    window.location.replace(redirectURL);
}
