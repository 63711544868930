import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Dispatch, SetStateAction } from 'react';
import { useTheme } from '../../themes/ThemeProvider';

interface ChildComponentProps {
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    selectValues: {value: string, label: string}[];
    title: string;
    label: string;
    function: (value: string) => void;
}

const StyledSelect: React.FC<ChildComponentProps> = ({ function: myFunction, setValue, value, selectValues, title, label }) => {
    const { currentTheme } = useTheme();
    
    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
        myFunction ? myFunction(event.target.value as string) : null
    };

    const style = {
        '& > :not(style)': { m: 1, width: '25ch' },
        // backgroundColor: currentTheme.raised,
        input: { color: currentTheme.onRaised },
        '& label': {
            color: currentTheme.secondary,
        },
        '& label.Mui-focused': {
            color: currentTheme.primary,
        },
        "& .MuiOutlinedInput-root": {
            '& fieldset': {
                borderColor: currentTheme.secondary,
            },
            '&:hover fieldset': {
                borderColor: currentTheme.secondary,
            },
            "&.Mui-focused fieldset": {
                borderColor: currentTheme.primary,
            }
        },
        '& .MuiSelect-icon': {
            color: currentTheme.primary,
            borderRadius: '4px',
          },
    }

    return (
        <Box sx={style}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"         
                    value={value}
                    label={label}
                    onChange={handleChange}
                    style={{background:currentTheme.raised, color: currentTheme.onRaised}}
                >
                    {selectValues.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default StyledSelect;