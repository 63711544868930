import { StyledButton } from "../../../buttons";

interface ChildComponentProps {
    handleNext: () => void;
    handlePrevious: () => void;
}

const CompleteSetup: React.FC<ChildComponentProps> = ({ handlePrevious, handleNext }) => {
    return (
        <div>
            <StyledButton
                onClick={ handlePrevious }
                text={'Back'}
                enabled={true}
            /> 
            <StyledButton
                onClick={ handleNext }
                text={'Continue'}
                enabled={true}
            /> 
        </div>
    );
}
  
export default CompleteSetup;