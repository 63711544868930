import { Typography } from '@mui/material';
import { useTheme } from '../../themes/ThemeProvider';

const StyledText = (props:any) => {
  const {
    marginTop, 
    marginLeft,
    children, 
    textAlign, 
    secondaryText, 
    variant
  } = props;
  const { currentTheme } = useTheme();

  return (
    <Typography 
      variant={variant ? variant : "body1" }
      style={{ 
        marginTop: marginTop ? marginTop : 0,
        marginLeft: marginLeft ? marginLeft : 0,
        color: secondaryText ? currentTheme.onSecondary: currentTheme.onSurface,
        flexGrow: 1,
        textAlign: textAlign,
        // fontFamily: "sans-serif"
      }}
    >
      {children}
    </Typography>
  );
};

export default StyledText;