import { Config } from "./types";

export const getConfigurationFromLocalStorage = () => {
    const localConfig: string = localStorage.getItem("config") as string;
    const parsedConfig = JSON.parse(localConfig)
    return parsedConfig;
}

export const isDomainStringValid = (domainString: string) => {
    if (domainString.length < 3 || domainString.includes(" ") || domainString.includes('.')) {
        return false;    // Contains at least one special character or space
    } else {
        return true
    }
}

export const isNameValid = (nameString: string) => {
    if (nameString.length < 3 || nameString.includes(" ")) {
        return false
    } else {
        return true
    }
}

export const isEmailValid = (emailString: string) => {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(emailString);
}

export const capitalizeLetters = (string: string, count: number) => {
    if (string.length < 2) {
        return string.toUpperCase();  // If the string has fewer than 2 characters, capitalize all characters
    }
    return string.substring(0, count).toUpperCase() + string.substring(count);
}

export const configsEqual = (oldConfig: Config, newConfig: Config) => {
    if (oldConfig?.id !== newConfig?.id) return false;
    if (oldConfig?.connected !== newConfig?.connected) return false;
    if (oldConfig?.tier !== newConfig?.tier) return false;
    return true;
}

export const formatDateUTC = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' });
};


export const checkValidUsers = (usersToInvite: any) => {
    let validUsersToInvite = []
    for (let i = 0; i < usersToInvite.length; i++) {
        if (isNameValid(usersToInvite[i].firstName) && isNameValid(usersToInvite[i].lastName) && isEmailValid(usersToInvite[i].email)) {
            validUsersToInvite.push(usersToInvite[i])
        }
    }
    return validUsersToInvite;
}

export const areArraysEqual = (arr1: any[], arr2: any[]) => {
    // Check if arrays have different lengths
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Compare each element of the arrays
    return arr1.every((value: any, index: number) => value === arr2[index]);
}

export const objectsEqual = (o1: any, o2: any): boolean =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every((p: string) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

export const arrayOfObjectsEqual = (a1: any[], a2: any[]): boolean => {
    return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
}