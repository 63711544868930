'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { X as XIcon } from '@phosphor-icons/react/dist/ssr/X';
import { useNavigate } from 'react-router-dom';
import { StyledText, StyledTitle } from '../text';
import { useTheme } from '../../themes/ThemeProvider';
import { StyledButton } from '../buttons';
import fetchData from '../../helpers/requests';
import { useAuth0 } from '@auth0/auth0-react';
import { checkValidUsers, isEmailValid, isNameValid } from '../../helpers/functions';
import StyledTextField from './StyledTextField';
import { useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';


const InviteUserModal = ({ 
    open, setOpen, 
    setEmailsSent, 
    setEmailsError, 
    setNumEmailsSent, triggerFunction
}: { 
    open: boolean; 
    setOpen: (open: boolean) => void; 
    emailsSent: boolean; 
    setEmailsSent: (emailsSent: boolean) => void; 
    emailsError: boolean; 
    setEmailsError: (emailsError: boolean) => void; 
    setNumEmailsSent: (numEmailsSent: number) => void; 
    triggerFunction: () => void
}) => {
    
    const { config, currentTheme } = useTheme();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [admin, setAdmin] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [allFieldsBlank, setAllFieldsBlank] = useState(true);

    useEffect(() => {
        let fieldsBlank = firstName === "" && lastName === "" && email === "";
        if(fieldsBlank) {
            setAllFieldsBlank(true);
        } else {
            setAllFieldsBlank(false);
        }
        if (isNameValid(firstName) && isNameValid(lastName) && isEmailValid(email)) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    }, [firstName, lastName, email]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, [navigate]);

    const inviteUser = async () => {
        const token = await getAccessTokenSilently();

        // firstName, lastName, title, email, admin
        const user = {
            firstName: firstName,
            lastName: lastName,
            title: title,
            email: email,
            admin: admin
        }

        const validUsersToInvite = checkValidUsers([user]);
        

        if (validUsersToInvite.length > 0) {
            setNumEmailsSent(validUsersToInvite.length)

            // only send valid emails
            const result = await fetchData({
                url: import.meta.env.VITE_UNEVU_API_URL + "/user/invite",
                method: "POST",
                domain: config?.name,
                token: token,
                code: 'null',
                body: { users: validUsersToInvite }
            });

            if (result.status === 200) {
                setEmailsSent(true)
                setEmailsError(false)
                triggerFunction();
            } else {
                setEmailsError(true)
                setEmailsSent(false)
            }
        } else {
            setEmailsError(true)
        }

        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiDialog-container': {},
                '& .MuiDialog-paper': { height: '60%', width: '40%', maxWidth: '400px', minWidth: '270px' },
            }}
        >
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                minHeight: 0,
                color: currentTheme.onSurface,
                background: currentTheme.surface,

            }}>
                <Stack direction="row" sx={{ alignItems: 'center', flex: '0 0 auto', justifyContent: 'space-between' }}>
                    <StyledTitle color={currentTheme.primary}>Invite User</StyledTitle>
                    <IconButton onClick={handleClose}>
                        <XIcon style={{ color: currentTheme.onSecondary }} />
                    </IconButton>
                </Stack>
                <Stack spacing={3} sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
                    <Stack spacing={3}>
                        <Card sx={{ borderRadius: 1, background: currentTheme.secondary }} variant="outlined">

                        </Card>
                    </Stack>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <StyledTextField
                            value={firstName}
                            label={"First Name"}
                            onChange={(event: { target: { value: string; }; }) => setFirstName(event.target.value)}
                            error={allFieldsBlank || firstName === "" ? false : !isNameValid(firstName)}
                        />
                        <StyledTextField
                            value={lastName}
                            label={"Last Name"}
                            onChange={(event: { target: { value: string; }; }) => setLastName(event.target.value)}
                            error={allFieldsBlank || lastName === "" ? false : !isNameValid(lastName)}
                        />
                        <StyledTextField
                            value={title}
                            label={"Title"}
                            onChange={(event: { target: { value: string; }; }) => setTitle(event.target.value)}
                            error={false}
                        />
                        <StyledTextField
                            value={email}
                            label={"Email"}
                            onChange={(event: { target: { value: string; }; }) => setEmail(event.target.value)}
                            error={allFieldsBlank || email === "" ? false : !isEmailValid(email)}
                        />
                        <FormControlLabel style={{ color: currentTheme.primary }}
                            control={
                                <Switch
                                    style={{ color: currentTheme.primary }}
                                    onChange={(event: { target: { checked: boolean; }; }) => {
                                        setAdmin(event.target.checked)
                                    }}
                                />
                            }
                            label="Admin"
                        />
                    </Box>
                    <Stack spacing={3}>
                        <Card sx={{ borderRadius: 1, background: currentTheme.secondary }} variant="outlined">
                            <Box sx={{ overflowX: 'auto' }}>
                                {/* <LineItemsTable rows={lineItems} /> */}
                            </Box>

                            {/* <Divider sx={{color: currentTheme.secondary}} /> */}
                        </Card>
                        <StyledButton onClick={inviteUser} text={"Invite"} enabled={enabled} />
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default InviteUserModal;