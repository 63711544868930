import CenteredContent from "../generic/CenteredContent";
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from "../../themes/ThemeProvider";

const PageLoader: React.FC = () => {
  const { currentTheme } = useTheme();
  const style = {
    color: currentTheme.primary
  }
  return (
    <CenteredContent height={"100vh"}>
      <CircularProgress style={style} disableShrink />
    </CenteredContent>
  );
};

export default PageLoader;