import React from 'react';
import { Minus } from '@phosphor-icons/react/dist/ssr/Minus';
import { useTheme } from '../../themes/ThemeProvider';

interface MinusIconProps {
    weight?: 'fill' | 'outline';
}

const MinusIcon: React.FC<MinusIconProps> = ({ weight }) => {
    const { currentTheme } = useTheme();
    return (
        <Minus
            style={{ fontWeight: weight === 'fill' ? 'bold' : 'normal', color: currentTheme.onPrimary, fontSize: 20 }}
        />
    );
};

export default MinusIcon;