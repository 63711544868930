import { createContext, useState, useContext, ReactNode } from 'react';
import overallTheme, { Theme, darkTheme } from './Theme';
import { Config, UserRole } from '../helpers/types';

type ThemeString = 'light' | 'dark';

interface ProviderProps {
  children: ReactNode
}

interface ThemeContextProps {
  theme: ThemeString;
  setTheme: React.Dispatch<React.SetStateAction<ThemeString>>;
  config: Config, 
  setConfig: React.Dispatch<React.SetStateAction<Config>>,
  currentTheme: Theme,
  userRole: UserRole,
  setUserRole: React.Dispatch<React.SetStateAction<UserRole>>
}

export const defaultConfig = {
  lastDataPull: "",
  id: "",
  loggedIn: false,
  connected: false,
  tier: "",
  name: "",
  user: {
    firstName: "",
    lastName: "",
    title: "",
    email: ""
  },
  organization: {
    name: "",
    size: "1-5"
  },
  subscription: {
    current_period_end: ""
  }
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: 'dark',
  setTheme: () => {},
  config: defaultConfig, 
  setConfig: () => {},
  currentTheme: darkTheme,
  userRole: "user",
  setUserRole: () => {}
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: React.FC<ProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeString>('dark');
  const [userRole, setUserRole] = useState<UserRole>('user');
  const [config, setConfig] = useState<Config>(defaultConfig);
  const currentTheme = overallTheme[theme];

  return (
    <ThemeContext.Provider value={{ theme, setTheme, currentTheme, config, setConfig, userRole, setUserRole}}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
