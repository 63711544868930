'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { CircleIcon, MinusIcon, ClockIcon, Chip, PencilIcon } from '../../components/icons';

import { DataTable } from './components/data-table';

import { useCustomersSelection } from './customers-selection-context';

import StyledText from '../../components/text/StyledText';


export function CustomersTable({ rows, setEditUserModalOpen }) {
  const { deselectAll, deselectOne, selectAll, selectOne, selected } = useCustomersSelection();

  const openModal = (row) => {
    setEditUserModalOpen(true, row);
  }

  const columns = [
    {
      formatter: (row) => (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center'}}>
          {/* <Avatar src={row.avatar} />{' '} */}
          <div>
            <StyledText>
              {row.name}
            </StyledText>
            <StyledText secondaryText>
              {row.email}
            </StyledText>
          </div>
        </Stack>
      ),
      name: 'Name',
      width: '250px',
    },
    // {
    //   formatter: (row) => (
    //     <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
    //       <LinearProgress sx={{ flex: '1 1 auto' }} value={row.quota} variant="determinate" />
    //       <Typography color="text.secondary" variant="body2">
    //         {new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(row.quota / 100)}
    //       </Typography>
    //     </Stack>
    //   ),
    //   name: 'Quota',
    //   width: '250px',
    // },
    { field: 'phone', name: 'Phone Number', width: '150px' },
    {
      formatter(row) {
        return new Date(row.createdAt).toLocaleDateString('en-US');
      },
      name: 'Created at',
      width: '200px',
    },
    {
      formatter: (row) => {
        const mapping = {
          active: { label: 'Active', icon: <CircleIcon  weight="fill" /> },
          blocked: { label: 'Blocked', icon: <MinusIcon /> },
          pending: { label: 'Pending', icon: <ClockIcon weight="fill" /> },
        };
        const { label, icon } = mapping[row.status] ?? { label: 'Unknown', icon: null };
  
        return <Chip icon={icon} label={label} size="small" variant="outlined" />;
      },
      name: 'Status',
      width: '150px',
    },
    {
      formatter: (row) => (
        <IconButton onClick={() =>openModal(row)}>
          <PencilIcon />
        </IconButton>
      ),
      name: 'Actions',
      hideName: true,
      width: '100px',
      align: 'right',
    },
  ];

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        onDeselectAll={deselectAll}
        onDeselectOne={(_, row) => {
          deselectOne(row.id);
        }}
        onSelectAll={selectAll}
        onSelectOne={(_, row) => {
          selectOne(row.id);
        }}
        rows={rows}
        selectable
        selected={selected}
        
      />
      {!rows.length ? (
        <Box sx={{ p: 3 }}>
          <StyledText>
            {"No customers found"}
          </StyledText>
        </Box>
      ) : null}
    </React.Fragment>
  );
}
