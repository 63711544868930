import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface BasicTooltipProps {
  title: string;
  icon?: any;
  sx?: any;
}

const BasicTooltip = ({ title, icon, sx }: BasicTooltipProps) => {
  return (
    <Tooltip title={title} sx={sx} placement='top'>
      <IconButton>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default BasicTooltip;