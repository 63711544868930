import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "../page-loader";

interface AuthenticationGuardProps {
  component: React.ComponentType<any>;
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />
  });

  return <Component />;
};

export default AuthenticationGuard;