import { Dispatch, SetStateAction } from "react";
import { StyledButton } from "../../../buttons";
import ZendeskLogin from "./ZendeskLogin";
import { useTheme } from "../../../../themes/ThemeProvider";
import fetchData from "../../../../helpers/requests";
import { useAuth0 } from "@auth0/auth0-react";
import DomainEntry from "./DomainEntry";

interface ChildComponentProps {
    handleNext: () => void;
    domain: string;
    setDomain: Dispatch<SetStateAction<string>>;
    apikey: string | undefined;
    setApikey: Dispatch<SetStateAction<string>>;
    apikeyEmail: string;
    setApikeyEmail: Dispatch<SetStateAction<string>>;
    connectionType: string;
}

const ZendeskSetup: React.FC<ChildComponentProps> = ({ handleNext, domain, setDomain, apikey, setApikey, apikeyEmail, setApikeyEmail, connectionType }) => {
    const { config } = useTheme();
    const { getAccessTokenSilently } = useAuth0();

    const saveConfigToDatabase = async () => {
        const token = await getAccessTokenSilently();

        await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config/update",
            method: "POST",
            domain: domain,
            token: token,
            code: undefined,
            body: { config: { addZendeskConfig: config } },
        });
    }

    const handleClick = () => {
        saveConfigToDatabase();
        handleNext()
    }

    return (
        <div>
            {connectionType != "oauth" ? <DomainEntry domain={domain} setDomain={setDomain} /> : null}
            <ZendeskLogin
                domain={domain}
                setDomain={setDomain}
                apikey={apikey}
                setApikey={setApikey}
                apikeyEmail={apikeyEmail}
                setApikeyEmail={setApikeyEmail}
                connectionType={connectionType}
            />

            {config?.connected ?
                <StyledButton
                    onClick={handleClick}
                    text={'Continue'}
                    enabled={true}
                /> : null
            }
        </div>
    );
}

export default ZendeskSetup;