import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '../../themes/ThemeProvider';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import CenteredContent from '../generic/CenteredContent';
import { StyledText } from '../text';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { LogoutButton } from '../buttons';

const SideDrawer = (props:any ) => {
    const { toggleDrawer, open } = props;
    const { userRole, currentTheme } = useTheme();
    const { user } = useAuth0();
    const navigate = useNavigate();
    
    const DrawerList = (
        <Box sx={{ 
                width: 250, 
                height: '100vh',
                color: currentTheme.onSurface, 
                backgroundColor: currentTheme.surface 
            }} 
            role="presentation" 
            onClick={toggleDrawer(false)}
        >
        <br/>
            <CenteredContent>
                <Button color="inherit"> 
                    <img src={user?.picture} alt={user?.name || ''} style={{ width: '90px', height: '90px', borderRadius: '50%' }}/>
                </Button> 
            </CenteredContent>
            <StyledText secondaryText={true} textAlign={"center"}>
                {user?.name}
            </StyledText>
      
            <StyledText secondaryText={true} textAlign={"center"}>
                {user?.email}
            </StyledText>
            <br/>
            <Divider  sx={{background: currentTheme.onSecondary}}/>
            <List>
                <ListItem onClick={() => navigate("/dashboard")} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <DashboardIcon sx={{color: currentTheme.onSurface}}/>
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'} />
                    </ListItemButton>
                </ListItem>
            </List>
           <Divider sx={{background: currentTheme.onSecondary}}/>
           {userRole === "admin" ? <List>
                <ListItem onClick={() => navigate("/admin")} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <PeopleIcon sx={{color: currentTheme.onSurface}} />
                        </ListItemIcon>
                        <ListItemText primary={'User Management'} />
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => navigate("/billing")} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <ShoppingCartIcon sx={{color: currentTheme.onSurface}} />
                        </ListItemIcon>
                        <ListItemText primary={'Billing & Payment'} />
                    </ListItemButton>
                </ListItem>
            </List>  : null }
            <Divider sx={{background: currentTheme.onSecondary}}/>
            <List>
                <CenteredContent>
                    <LogoutButton />
                </CenteredContent>
            </List>
        </Box>
    );

    return (
        <div >
            <Drawer 
                open={open} 
                onClose={toggleDrawer(false)}
            >
                {DrawerList}
            </Drawer>
        </div>
    );
}

export default SideDrawer;