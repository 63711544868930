import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { PlugsConnected as PlugsConnectedIcon } from '@phosphor-icons/react/dist/ssr/PlugsConnected';
import { useTheme } from '../../../../themes/ThemeProvider';
import { ConnectionCard, SelectionCard, StyledButton } from '../../../buttons';
import CenteredContent from '../../../generic/CenteredContent';
import ZendeskSetup from '../ZendeskSetup/ZendeskSetup';
import { StyledTitle } from '../../../text';
import { capitalizeLetters } from '../../../../helpers/functions';

export function Integrations({ integrations, domain, setDomain, apikey, setApikey, apikeyEmail, setApikeyEmail }) {
  const [activeStep, setActiveStep] = useState(0);
  const [zendeskSelected, setZendeskSelected] = useState(false);
  const [connectionType, setConnectionType] = useState("");
  const { config, setConfig } = useTheme()

  const setZendeskCRM = () => {
    setZendeskSelected(true)
  }

  const changeConnectionType = (value, step) => {
    setConnectionType(value)
    setActiveStep(step)
    let newConfig = config;
    newConfig["connection"] = {
      type: value,
      source: "zendesk"
    }

    setConfig(newConfig);
    localStorage.setItem("config", JSON.stringify(newConfig))
  }

  const zendeskOptions = [
    {
      id: 'oauth',
      name: 'OAuth',
      description: 'An easy way to connect requiring very few steps',
      installed: false,
      onclick: () => {
        changeConnectionType('oauth', 2)
      },
    }, {
      id: 'apikey',
      name: 'API Key',
      description: 'For admins who can provide access using their api key',
      installed: false,
      onclick: () => {
        changeConnectionType('apikey', 2)
      },
    },
  ]

  const { currentTheme } = useTheme();

  return (
    <Card sx={{ background: "#f2f2f2" }}>
      <CardHeader
        sx={{ color: currentTheme.primary }}
        avatar={
          <Avatar sx={{ background: currentTheme.primary }}>
            <PlugsConnectedIcon fontSize="var(--Icon-fontSize)" />
          </Avatar>
        }
        title={"Integrations" + (zendeskSelected ? " > Zendesk" : "") + (activeStep === 2 ? " > " + connectionType : "")}
      />
      <CardContent>
        <Card sx={{ borderRadius: 1, background: currentTheme.raised }} variant="outlined">
          <Stack divider={<Divider />}>

            {/* First Step */}
            {!zendeskSelected && !config?.loggedIn ? integrations.map((integration) => (
              <SelectionCard key={integration.icon} icon={""} title={"Zendesk"}
                description={"For individuals just looking to browse their data while collaborating with unlimited team members for a cheap cheap option."}
                onClick={() => {
                  setZendeskCRM()
                  setActiveStep(1)
                }}
              />
            )) : null}

            {/* IF LOGGED IN & CONNECTED TO ZENDESK */}
            {!zendeskSelected && config?.loggedIn ?
              <div style={{ backgroundColor: currentTheme.secondary }}>
                <StyledTitle textAlign={"center"}>{"Logged in to " + capitalizeLetters(config?.connection?.source, 1) + ", Connection Type: " + capitalizeLetters(config?.connection?.type, config?.connection?.type === "oauth" ? 2 : 4)}</StyledTitle>
                {integrations.map((integration) => (
                  <SelectionCard key={integration.icon} icon={""} title={"Zendesk"}
                    description={"For individuals just looking to browse their data while collaborating with unlimited team members for a cheap cheap option."}
                    onClick={() => {

                    }}
                    onClickDisabled={true}
                  />
                ))}
              </div> : null}

            {/* Second Step */}
            {zendeskSelected && activeStep === 1 ? zendeskOptions.map((option) => (
              <SelectionCard
                key={option.id}
                icon={""}
                title={option.name}
                description={option.description}
                onClick={option.onclick}
              />
            )) : null}

            {/* Third Step */}
            {zendeskSelected && connectionType === "oauth" ?
              <ConnectionCard
                icon={""}
                title={"OAuth Setup"}
                description={"Enter your OAuth credentials to connect your Zendesk account."}
                component={
                  <ZendeskSetup
                    handleNext={'handleNext'}
                    domain={domain}
                    setDomain={setDomain}
                    connectionType={connectionType}
                  />
                }
              />

              : null}

            {zendeskSelected && connectionType === "apikey" ?
              <div>
                <ConnectionCard
                  icon={""}
                  title={"API Key Setup"}
                  description={"Enter your API Key to connect your Zendesk account."}
                  height={320}
                  component={
                    <ZendeskSetup
                      handleNext={'handleNext'}
                      domain={domain}
                      setDomain={setDomain}
                      apikey={apikey}
                      setApikey={setApikey}
                      apikeyEmail={apikeyEmail}
                      setApikeyEmail={setApikeyEmail}
                      connectionType={connectionType}
                    />
                  }
                />
              </div>

              : null}

            {/* Back Button */}
            {zendeskSelected ?
              <CenteredContent sx={{ width: "100%" }}>
                <StyledButton
                  onClick={() => {
                    activeStep === 1 ?
                      setZendeskSelected(false) :
                      changeConnectionType("", 1)
                  }}
                  text={"Back"}
                  enabled
                />
              </CenteredContent>
              : null}

          </Stack>
        </Card>
      </CardContent>
    </Card>
  );
}
