import * as React from 'react';
import Stack from '@mui/material/Stack';

import { Integrations } from './integrations';

const metadata = { title: `Integrations | Settings | Dashboard` };

const IntegrationsList = ({domain, setDomain, apikey, setApikey, apikeyEmail, setApikeyEmail}) => {
  return (
    <div style={{paddingTop:"10px"}}>
        <title>{metadata.title}</title>
      <Stack spacing={4}>
        <Integrations
          domain={domain}
          setDomain={setDomain}
          apikey={apikey}
          setApikey={setApikey}
          apikeyEmail={apikeyEmail}
          setApikeyEmail={setApikeyEmail}
          integrations={[
            {
              id: 'zendesk',
              name: 'Zendesk',
              icon: '/zendesk.png',
              description: 'See your usage and manage your apps',
              installed: false,
            }
          ]}
        />
      </Stack>
    </div>
  );
}


export default IntegrationsList;