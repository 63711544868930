import { DateRangePicker } from 'rsuite';
import "./styles.css";

const MyDatePicker = (props: any) => {
  const { changeDateFilterFunction, defaultDate } = props;

  const changeDateFilter = (value: any) => {
    changeDateFilterFunction(value)
  }

  return <div style={{ paddingTop: '10px' }}>
    < DateRangePicker onOk={(value) => { changeDateFilter(value) }} placeholder="Select Date Range" defaultValue={defaultDate}/>
  </div>
}

export default MyDatePicker;