import { useAuth0 } from "@auth0/auth0-react";
import StyledButton from "./StyledButton";

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard"
      },
    });
  };

  return (
    <StyledButton onClick={handleLogin} text={"Log In"} enabled={true} />
  );
};

export default LoginButton;