import { Dispatch, SetStateAction } from "react";
import { StyledTextField } from "../..";
import { isEmailValid, isNameValid } from "../../../../helpers/functions";

interface ChildComponentProps {
    centered: boolean;
    firstName: string;
    setFirstName: Dispatch<SetStateAction<string>>;
    lastName: string;
    setLastName: Dispatch<SetStateAction<string>>;
    title: string;
    setTitle: Dispatch<SetStateAction<string>>;
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
}

const UserDetails: React.FC<ChildComponentProps> = ({centered, firstName, setFirstName, lastName, setLastName, title, setTitle, email, setEmail}) => {
    const centeredStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    const user = 
        <div style={centered ? centeredStyle : {}}>
            <StyledTextField 
                defaultValue={firstName}
                value={firstName}
                error={isNameValid(firstName) || firstName.length == 0  ? false : true}
                label={"First Name"}
                onChange={(event: { target: { value: string; }; }) => setFirstName(event.target.value)} 
            />
            <StyledTextField 
                defaultValue={lastName}
                value={lastName}
                error={isNameValid(lastName) || lastName.length == 0  ? false : true}
                label={"Last Name"}
                onChange={(event: { target: { value: string; }; }) => setLastName(event.target.value)} 
            />
            <StyledTextField 
                defaultValue={title}
                value={title}
                label={"Title"}
                onChange={(event: { target: { value: string; }; }) => setTitle(event.target.value)} 
            />
            <StyledTextField 
                defaultValue={email}
                value={email}
                error={isEmailValid(email) || email.length == 0 ? false : true}
                label={"Email"}
                onChange={(event: { target: { value: string; }; }) => setEmail(event.target.value)} 
            />
        </div>

    return (
        <div>
            {user}
        </div>
    );
};

export default UserDetails;