import { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  Navigate,
  useNavigate
} from "react-router-dom";
import { StyledText } from "../../components/text";
import { StyledButton } from "../../components/buttons";
import CenteredContent from "../../components/generic/CenteredContent";
import { Card, CardActionArea, CardHeader } from "@mui/material";
import { useTheme } from "../../themes/ThemeProvider";
import { Config } from "../../helpers/types";
import fetchData from "../../helpers/requests";
import { useAuth0 } from "@auth0/auth0-react";


const ReturnPage = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const navigate = useNavigate();
  const { currentTheme, config } = useTheme();
  const {getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    getSessionStatus(sessionId, config, setStatus, setCustomerEmail, getAccessTokenSilently);
  }, []);

  if (status === 'open') {
    // Broken
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <Card
          sx={{
            border: '1px solid rgba(0,0,0,0.1);',
            borderRadius: '10px',
            background: currentTheme.surface,
            color: currentTheme.onSurface,
            width: '50%',
            margin: '0 auto',
            padding: '20px',
            marginTop: '5vh',
          }}
        >
          <CardHeader
            title="Payment Confirmation"
          />
          <CardActionArea sx={{background: currentTheme.raised, color: currentTheme.onRaised}}>
            <div style={{marginLeft:'10px'}}>
            <br />
            <br />
            <br />
            <StyledText >
              We appreciate your business! A confirmation email will be sent to {customerEmail}.
            </StyledText>
            <br />
            <StyledText>
              If you have any questions, please email <a style={{ color: 'dodgerblue'}} href="mailto:info@unevu.com">info@unevu.com</a>.
            </StyledText>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </div>
          </CardActionArea>
          <CenteredContent>
            <div style={{marginTop:'10px'}}>
            <StyledButton
              onClick={() => navigate("/dashboard")}
              text={"Dashboard"}
              enabled={true}
            />
            </div>
              
          </CenteredContent>

        </Card>


      </section>
    )
  }

  return null;
}

export default ReturnPage;

const getSessionStatus = async (sessionId: string | null, config: Config, setStatus: Dispatch<SetStateAction<null>>, setCustomerEmail: Dispatch<SetStateAction<string>>, getAccessTokenSilently: unknown) => {
  const token = await (getAccessTokenSilently as () => Promise<string>)();
    const result = await fetchData({
      url: `${import.meta.env.VITE_UNEVU_API_URL}/checkout/session/save?session_id=${sessionId}`, 
      method: "GET",
      domain: config?.name,
      token: token,
  })

  setStatus(result.checkout_status);
  setCustomerEmail(result.customer_email);
}