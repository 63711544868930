'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { X as XIcon } from '@phosphor-icons/react/dist/ssr/X';
import { useNavigate } from 'react-router-dom';
import { StyledText, StyledTitle } from '../text';
import { useTheme } from '../../themes/ThemeProvider';
import { StyledButton } from '../buttons';
import { CenteredContent } from '../generic';
import fetchData from '../../helpers/requests';
import { useAuth0 } from '@auth0/auth0-react';


const Modal = ({ open, setOpen, triggerFunction }: { open: boolean; setOpen: (open: boolean) => void; setShowAlert: (open: boolean) => void; triggerFunction: () => void }) => {
    const { config, currentTheme } = useTheme();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();  

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, [navigate]);

    const cancelSubscription = async () => {
        const token = await getAccessTokenSilently();

        const result = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/checkout/subscription/cancel", 
            method: "GET",
            domain: config?.name,
            token: token,
        });
        setOpen(false);
        triggerFunction();
    }

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiDialog-container': {},
                '& .MuiDialog-paper': { height: '50%', width: '100%' },
            }}
        >
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                minHeight: 0,
                color: currentTheme.onSurface,
                background: currentTheme.surface,

            }}>
                <Stack direction="row" sx={{ alignItems: 'center', flex: '0 0 auto', justifyContent: 'space-between' }}>
                    <StyledTitle color={currentTheme.primary}>Cancelling Your Subscription</StyledTitle>
                    <IconButton onClick={handleClose}>
                        <XIcon style={{ color: currentTheme.onSecondary }} />
                    </IconButton>
                </Stack>
                <Stack spacing={3} sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
                    <Stack spacing={3}>
                        <Card sx={{ borderRadius: 1, background: currentTheme.secondary }} variant="outlined">

                        </Card>
                        <CenteredContent>
                            <PriorityHighIcon sx={{ color: 'red' }} />
                        </CenteredContent>
                        <StyledText secondaryText variant="body2">
                            You are about to delete your subscription. This action cannot be undone. At the end of your billing period you will no longer have access to the application.
                        </StyledText>
                        <StyledText secondaryText variant="body2">
                            We will retain your data for 30 days after your subscription ends. After that, your data will be permanently deleted.
                        </StyledText>
                    </Stack>
                    <Stack spacing={3}>
                        <StyledTitle color={currentTheme.primary}>Are you sure?</StyledTitle>
                        <Card sx={{ borderRadius: 1, background: currentTheme.secondary }} variant="outlined">
                            <Box sx={{ overflowX: 'auto' }}>
                                {/* <LineItemsTable rows={lineItems} /> */}
                            </Box>

                            {/* <Divider sx={{color: currentTheme.secondary}} /> */}
                        </Card>
                        <StyledButton onClick={cancelSubscription} text={"Cancel My Subscription"} enabled />
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default Modal;