'use client';

import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '../../themes/ThemeProvider';
function noop() {
  return undefined;
}

export function CustomersPagination({ count, page }) {
  // You should implement the pagination using a similar logic as the filters.
  // Note that when page change, you should keep the filter search params.
  const { currentTheme } = useTheme();
  return (
    <TablePagination
      sx={{
        color: currentTheme.primary,
        '& .MuiSelect-icon': {
          color: currentTheme.primary,
          borderRadius: '4px',
        },
      }}
      component="div"
      count={count}
      onPageChange={noop}
      onRowsPerPageChange={noop}
      page={page}
      rowsPerPage={5}
      rowsPerPageOptions={[5, 10, 25]}
    />
  );
}
