import { Link } from "react-router-dom";
import { StyledText } from "../../../components/text";

const BillingFooter = () => {
    return (
        <StyledText secondaryText={true} textAlign={"center"}>
            {"Your subscription can be cancelled at any time."}
            <br />
            <Link
                to="https://billing.stripe.com/p/login/6oE6q8fMZbwz8s8eUU"
            >
                {"Manage your subscription directly through Stripe."}
            </Link>
        </StyledText>
    );
}

export default BillingFooter;