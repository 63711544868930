import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';
import { TopNavbar } from "../../components/navigation";
import { PageLoader } from "../../components/page-loader";
import fetchData from "../../helpers/requests";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultConfig, useTheme } from "../../themes/ThemeProvider";
import { Config } from "../../helpers/types";
import { configsEqual } from "../../helpers/functions";
// @ts-ignore
import List from './List';

const AdministrationPage = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { currentTheme, config, setConfig, } = useTheme();
    const [loadingConfig, setLoadingConfig] = useState(true);
    const [allowRedirect, setAllowRedirect] = useState(false);
    const [rows, setRows] = useState([]);
    const [triggerRefresh, setTriggerRefresh] = useState(false)

    const refreshPage = () => {
        setTriggerRefresh(!triggerRefresh)
    }
    
    const getDashboardConfig = async (config: Config, setConfig: Dispatch<SetStateAction<Config>>) => {
        const token = await getAccessTokenSilently();
    
        // have user, give domain -> Make endpoint
        const userDomain = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/user", // /user?email='<string>' once endpoint is ready
            method: "GET",
            token: token
        })

        const domainConfig = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config", // /user?email='<string>' once endpoint is ready
            method:"GET",
            domain: userDomain?.data?.domains ? userDomain?.data?.domains[0] : null,
            token: token
        })

        const userList = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/user/list", // /user?email='<string>' once endpoint is ready
            method:"GET",
            domain: userDomain?.data?.domains ? userDomain?.data?.domains[0] : null,
            token: token
        })
        setRows(userList.data)
        
        if (domainConfig && domainConfig.data != null) {
            if(!configsEqual(config, domainConfig.data)) {
                setConfig(domainConfig.data);
                const stringifiedDomainConfig = JSON.stringify(domainConfig.data)
                localStorage.setItem("config", stringifiedDomainConfig)
            }
            // Perform additional processing here
        } else {
            setConfig(defaultConfig)
        }
        setLoadingConfig(false);
    }
    
    if (isLoading) {
        return <PageLoader />
    }
    
    if(!isAuthenticated) {
        return <Navigate replace to="/login" />
    }

    useEffect(() => {
        getDashboardConfig(config, setConfig);
    }, [triggerRefresh]);

    useEffect(() => {
        if(!loadingConfig) {
            setAllowRedirect(true);
        }
    }, [loadingConfig]);

    if(allowRedirect && !config.connected) {  // OR USER AN ADMIN
        return <Navigate replace to="/configuration" />
    }

    return (
        <>
            {config.connected ? 
                <>
                    <TopNavbar menu={true} title={"User Management"} />
                    <div style={{  
                        "display": "flex",
                        "justifyContent": "center",
                        "alignItems": "center",
                        "padding": "10px"
                    }}>
                        <div style={{
                            background:currentTheme.surface,
                            color:currentTheme.onSurface,
                            borderRadius: '10px',
                            padding:'10px',
                            width: '98%',
                        }}>
                            <List rows={rows} refreshPage={refreshPage}/>
                        </div>           
                    </div>
                </> : null
            }
        </>
    );
}
  
export default AdministrationPage;