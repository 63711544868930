import { TopNavbar } from "../../components/navigation";

const CallbackPage: React.FC = () => {
  return (
    <div>
      <TopNavbar />
    </div>
  );
};

export default  CallbackPage;