import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Grid } from '@mui/material';
import { useTheme } from '../../themes/ThemeProvider';
import { StyledText, StyledTitle } from '../text';
import ZendeskLogo from '../../assets/ZendeskLogo.png';

const SelectionCard = (props: any) => {
    const { title, description, onClick, component, onClickDisabled } = props;
    const { currentTheme } = useTheme();

    return (
        <Card sx={{
            minHeight: 100,
            maxHeight: 100,
            margin: '3px',
            backgroundColor: currentTheme.raised,
            color: currentTheme.onRaised,
            border: "2px solid " + currentTheme.secondary
        }}>
            <CardActionArea sx={{ minHeight: 100, maxHeight: 100, cursor: onClickDisabled ? "not-allowed" : "pointer" }} onClick={!onClickDisabled ? onClick : undefined}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3} md={3}>
                            <img src={ZendeskLogo} alt="Zendesk Logo" style={{ width: 105.208333333, height: 75 }} />
                        </Grid>
                        <Grid item xs={9} md={9}>
                            <StyledTitle variant={"h6"} textAlign={"left"}>
                                {title}
                            </StyledTitle>
                            <StyledText secondaryText variant={'body3'}>
                                {description}
                            </StyledText>
                            {component}
                        </Grid>

                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default SelectionCard;