import { useAuth0, LogoutOptions } from "@auth0/auth0-react";
import StyledButton from "./StyledButton";

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    const logoutOptions: LogoutOptions = {
      logoutParams: {
        returnTo: window.location.origin + "/login",
      },
    };
    localStorage.clear();
    logout(logoutOptions);
  };

  return (
    <StyledButton onClick={handleLogout} text={"Log Out"} enabled={true} />
  );
};

export default LogoutButton;