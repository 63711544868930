import React, { ReactNode } from 'react';
import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';

const HighlightProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    React.useEffect(() => {
        if (import.meta.env.VITE_NODE_ENV !== 'development') {
            console.log('initializing')
            H.init('1epkpj7g', {
                serviceName: 'reporting-app',
                tracingOrigins: false, // Set to true to enable tracing for all origins
                inlineImages: false, // Set to true to enable inline images
                networkRecording: {
                    enabled: true,
                    recordHeadersAndBody: true,
                    urlBlocklist: [
                        // insert full or partial urls that you don't want to record here
                        // Out of the box, Highlight will not record these URLs (they can be safely removed):
                        'https://www.googleapis.com/identitytoolkit',
                        'https://securetoken.googleapis.com',
                    ],
                },
            });
        }
    }, []);

    return (
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
    );
};

export default HighlightProvider;
