import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Stack, Typography, Grid, Avatar } from '@mui/material';
import { CreditCard as CreditCardIcon } from '@phosphor-icons/react/dist/ssr/CreditCard';
import { PropertyItem } from '../../../components/core/property-item';
import { PropertyList } from '../../../components/core/property-list';
import { useTheme } from '../../../themes/ThemeProvider';
import StyledButton from '../../../components/buttons/StyledButton';
import Modal from '../../../components/forms/Modal';

export default function SubscriptionDetails(props) {
  const { config, currentTheme } = useTheme()
  const { setOpenCheckoutPage, triggerRefresh, setTriggerRefresh, pricingTier, pricingTierName, setShowAlert } = props;
  const [modalOpen, setModalOpen] = React.useState(false)

  const cancelSubscription = () => {
    setTriggerRefresh(!triggerRefresh)
  }

  return (
    <React.Fragment >
      <Stack spacing={2} style={{ width: "48.5vw" }} >
        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column'

            }}
          >
            <Stack spacing={4}>
              <Card
                sx={{
                  border: '1px solid rgba(0,0,0,0.1);',
                  borderRadius: '10px',
                  background: currentTheme.surface,
                  color: currentTheme.onSurface,
                }}
              >
                <CardHeader
                  // action={
                  //   <Button color="secondary" startIcon={<PencilSimpleIcon />}>
                  //     Edit
                  //   </Button>
                  // }
                  avatar={
                    <Avatar sx={{
                      bgcolor: currentTheme.primary,
                      boxShadow: 'var(--mui-shadows-8)',
                    }} >
                      <CreditCardIcon sx={{ color: currentTheme.raised, background: currentTheme.onRaised }} fontSize="var(--Icon-fontSize)" />
                    </Avatar>
                  }
                  title="Premium Subscription Details"
                />
                <CardContent>
                  <Card variant="outlined" sx={{ border: `1px solid ${currentTheme.background}` }}>
                    <PropertyList
                      divider={<Divider sx={{ color: currentTheme.onSurface, background: currentTheme.background }} />}
                      sx={{
                        '--PropertyItem-padding': '12px 24px',
                        height: '100%',
                        background: currentTheme.raised,
                        color: currentTheme.onRaised
                      }}
                    >
                      {[
                        { key: 'Price', value: "$20 / Month"},
                        {
                          key: 'Pricing Details',
                          value: (
                            <Typography variant="subtitle2">
                              Cancel your subscription at any time
                              <br />
                              Billed monthly
                              <br />
                              Payment will start at the end of your free trial
                            </Typography>
                          ),
                        },
                        {
                          key: 'Features',
                          value: (
                            <Typography variant="subtitle2">
                              Updates your data every 15 minutes
                              <br />
                              Unlimited data storage
                            </Typography>
                          ),
                        },
                        {
                          key: '',
                          value: (
                            <StyledButton
                              enabled
                              text={config?.tier === pricingTier || config?.tier === "cancel-pending" || config?.tier === "expired" ? "Subscribe" : "Cancel Subscription"}
                              onClick={config?.tier === pricingTier || config?.tier === "cancel-pending" || config?.tier === "expired" ? () => setOpenCheckoutPage(true) : () => setModalOpen(true)}
                            />
                          ),
                        }
                      ].map((item) => (
                        <PropertyItem key={item.key} name={item.key} value={item.value} />
                      ))}
                    </PropertyList>
                  </Card>
                </CardContent>
                <Modal open={modalOpen} setOpen={setModalOpen} triggerFunction={cancelSubscription}></Modal>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
}