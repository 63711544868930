import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from 'react-router-dom';
import { TopNavbar } from "../../components/navigation";
import { PageLoader } from "../../components/page-loader";
import { MongoDBDashboard } from "../../components/visualizations";
import { StyledText } from "../../components/text";
import fetchData from "../../helpers/requests";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultConfig, useTheme } from "../../themes/ThemeProvider";
import { Config } from "../../helpers/types";
import { configsEqual } from "../../helpers/functions";
import ReactGA from 'react-ga4';
import CustomizedSnackbars from "../../components/forms/UserFeedback/CustomizedSnackbars";

const Dashboard = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { config, setConfig } = useTheme();
    const [loadingConfig, setLoadingConfig] = useState(true);
    const [allowRedirect, setAllowRedirect] = useState(false);

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState("success")
    const [alertVariant, setAlertVariant] = useState("filled")
    const [alertMessage, setAlertMessage] = useState<React.ReactNode>("")

    const navigate = useNavigate();

    const dashboardId: string = import.meta.env.VITE_MONGODB_DASHBOARD_ID!;
    const chartsProjectId: string = import.meta.env.VITE_MONGODB_CHARTS_PROJECT_ID!;

    if (isLoading) {
        return <PageLoader />
    }

    // TODO: Add check if connected & redirect to login
    if (!isAuthenticated) {
        return <Navigate replace to="/login" />
    }

    const getDashboardConfig = async (token: string, config: Config, setConfig: Dispatch<SetStateAction<Config>>, setLoadingConfig: Dispatch<SetStateAction<boolean>>) => {
        // have user, give domain -> Make endpoint
        const userDomain = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/user", // /user?email='<string>' once endpoint is ready
            method: "GET",
            token: token
        })

        let domainConfig;

        if (userDomain?.data?.domains && userDomain?.data?.domains.length > 0) {
            domainConfig = await fetchData({
                url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config", // /user?email='<string>' once endpoint is ready
                method: "GET",
                domain: userDomain?.data?.domains ? userDomain?.data?.domains[0] : null,
                token: token
            })
        } else if (userDomain.error) {
            navigate("/login");
        }

        if (domainConfig && domainConfig.data != null) {

            // SET CONFIG

            if (!configsEqual(config, domainConfig.data)) {
                setConfig(domainConfig.data);
                const stringifiedDomainConfig = JSON.stringify(domainConfig.data)
                localStorage.setItem("config", stringifiedDomainConfig)
            }
            return domainConfig;
            // Perform additional processing here
        } else {
            setConfig(defaultConfig)
        }
        setLoadingConfig(false);

        return defaultConfig;
    }

    const initiateDashboard = async (): Promise<void> => {
        const token = await getAccessTokenSilently();
        const newConfig = await getDashboardConfig(token, config, setConfig, setLoadingConfig);
        const trialData: any = await getTrialInfo(token, config);

        if (newConfig !== defaultConfig) {
            if (trialData?.tier === "trial") {
                setShowAlert(true)
                setAlertType("info")
                setAlertMessage(`You are currently on a free trial.You currently have ${trialData?.daysRemaining} days left.`)
            } else if(trialData?.tier === "expired") {
                setShowAlert(true)
                setAlertType("error")
                setAlertMessage(
                    <>
                        Your trial has expired. Please{" "}
                        <a href="http://localhost:5173/billing">sign up</a> to re-enable data upload.
                    </>
                );
            }
        }
    }

    useEffect(() => {
        initiateDashboard();
    }, []);

    useEffect(() => {
        if (!loadingConfig) {
            setAllowRedirect(true);
        }
    }, [loadingConfig]);

    if (allowRedirect && !config.connected) {  // OR USER AN ADMIN
        return <Navigate replace to="/configuration" />
    }

    return (
        <>
            {ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Custom Title" })}

            {config.connected ?
                <>
                    <TopNavbar menu={true} title={"Dashboard"} />
                    <MongoDBDashboard
                        dashboardId={dashboardId}
                        chartsProjectId={chartsProjectId}
                        width="1500px"
                        height="650px"
                        authenticated={true}
                    />
                    <StyledText secondaryText={true} textAlign={"center"}>
                        {"Hover over the charts to learn more"}
                    </StyledText>
                    {showAlert ? <CustomizedSnackbars resetFunc={setShowAlert} severity={alertType} message={alertMessage} variant={alertVariant}/> : null}

                </> : null
            }
        </>
    );
}

export default Dashboard;

const getTrialInfo = async (token: string, config: Config) => {
    const queryParams = new URLSearchParams({
        domain: config?.name
    });
    const trialInfo = await fetchData({
        url: `${import.meta.env.VITE_UNEVU_API_URL}/domain/config/trialInfo?${queryParams}`, // /user?email='<string>' once endpoint is ready
        method: "GET",
        token: token
    })

    return trialInfo;
}