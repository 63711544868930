import { useAuth0 } from "@auth0/auth0-react";
import { TopNavbar } from "../../components/navigation";
import { PageLoader } from "../../components/page-loader";
import { MongoDBDashboard } from "../../components/visualizations";
import { StyledText } from "../../components/text";
import { LoginButton } from "../../components/buttons";

const Dashboard = () => {
    const { isLoading } = useAuth0();
    const dashboardId : string = import.meta.env.VITE_MONGODB_UNAUTH_DASHBOARD_ID!;
    const chartsProjectId : string = import.meta.env.VITE_MONGODB_UNAUTH_CHARTS_PROJECT_ID!
    
    if (isLoading) {
        return <PageLoader />
    }
    
    return (
        <>
            <TopNavbar menu={false} title={"Demo Dashboard"}/>
            <MongoDBDashboard
                dashboardId={ dashboardId } 
                chartsProjectId={ chartsProjectId }
                width = "1400px"
                height = "550px"
                authenticated = { false }
            />
            <StyledText textAlign={"center"} marginTop={-15}>
                {"Login to see your data!"}
            </StyledText>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <LoginButton />
            </div>
        </>
    );
}
  
export default Dashboard;