import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../../components/buttons";
import { PageLoader } from "../../components/page-loader";

import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { ArrowLeft as ArrowLeftIcon } from '@phosphor-icons/react/dist/ssr/ArrowLeft';

// @ts-ignore
import { SplitLayout } from "./split-layout";
// @ts-ignore
import { RouterLink } from './link';
// @ts-ignore
import { DynamicLogo } from './logo';

import { useTheme } from '../../themes/ThemeProvider'


const metadata = { title: `Sign in` };

const LoginPage = () => {
    const { isLoading } = useAuth0();
    const { currentTheme } = useTheme();

    if (isLoading) {
        return <PageLoader />
    }

    return (
        <React.Fragment >
            <title>{metadata.title}</title>
            <SplitLayout>
                <Stack spacing={3}>
                    <Box
                        component="img"
                        sx={{
                            scale: '80%'
                        }}
                        alt="unevu logo"
                        src="https://unevu.com/icons/unevu-big-trans-bkg.png"
                    />
                </Stack>
                <Stack spacing={3}>
                    <div>
                    </div>
                    <Link
                        color="text.primary"
                        component={RouterLink}
                        href={"/demo"}
                        sx={{ alignItems: 'center', display: 'inline-flex', gap: 1, color: currentTheme.onSecondary }}
                        variant="subtitle2"
                    >
                        <ArrowLeftIcon fontSize="var(--icon-fontSize-md)" />
                        Check out the Demo
                    </Link>
                    <Stack spacing={0}>
                        <LoginButton />
                        <div>
                            {/* <Link variant="subtitle2">Forgot password?</Link> */}
                        </div>
                    </Stack>
                </Stack>
            </SplitLayout>
        </React.Fragment>
    );
}

export default LoginPage;