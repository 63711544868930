import { Paper } from "@mui/material";
import { useTheme } from "../../themes/ThemeProvider";

const Form = (props: any) => {
    const { width } = props;
    const { currentTheme } = useTheme();

    const style = {
        width: width + "px",
        padding: "20px",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        backgroundColor: currentTheme.surface
    }

    return (
        <Paper sx={style}>
            {props.children}
        </Paper>
    );
};

export default Form;