import { Menu, MenuItem } from "@mui/material"
import { LogoutButton } from "../buttons";

const ProfileDropdown = (props: any) => {
    const { open, anchorEl, handleClose } = props;

    return (
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem
                onClick={handleClose}
            >
                Profile
            </MenuItem>
            <MenuItem
            >
                <LogoutButton />
            </MenuItem>
        </Menu>
    )
}

export default ProfileDropdown;