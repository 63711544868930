'use client';

import { X as XIcon } from '@phosphor-icons/react/dist/ssr/X';
import { useNavigate } from 'react-router-dom';
import { StyledTitle } from '../text';
import { useTheme } from '../../themes/ThemeProvider';
import { StyledButton } from '../buttons';
import fetchData from '../../helpers/requests';
import { useAuth0 } from '@auth0/auth0-react';
import { checkValidUsers, isEmailValid, isNameValid } from '../../helpers/functions';
import StyledTextField from './StyledTextField';
import { useEffect, useState, useCallback } from 'react';
import { FormControlLabel, Switch, Stack, IconButton, DialogContent, Dialog, Card, Box } from '@mui/material';
import { inputLabelClasses } from "@mui/material/InputLabel";


const EditUserModal = ({
    open, setOpen,
    setEmailsSent,
    setEmailsError,
    setNumEmailsSent, triggerFunction, selectedUser,
    setUserUpdated, setUserError
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    emailsSent: boolean;
    setEmailsSent: (emailsSent: boolean) => void;
    emailsError: boolean;
    setEmailsError: (emailsError: boolean) => void;
    setNumEmailsSent: (numEmailsSent: number) => void;
    triggerFunction: () => void;
    selectedUser: any;
    setUserUpdated: (userUpdated: boolean) => void;
    setUserError: (userError: boolean) => void;
}) => {

    const { config, currentTheme } = useTheme();
    const navigate = useNavigate();
    const { user, getAccessTokenSilently } = useAuth0();
    const [firstName, setFirstName] = useState(selectedUser?.firstName ? selectedUser.firstName : "");
    const [lastName, setLastName] = useState(selectedUser?.lastName ? selectedUser.lastName : "");
    const [title, setTitle] = useState(selectedUser?.title ? selectedUser.title : "");
    const [email, setEmail] = useState(selectedUser?.email ? selectedUser.email : "");
    const [admin, setAdmin] = useState(selectedUser?.role === "admin" ? true : false);
    const [status, setStatus] = useState(selectedUser?.status ? selectedUser.status : "");
    const [enabled, setEnabled] = useState(false);
    const [allFieldsBlank, setAllFieldsBlank] = useState(true);

    useEffect(() => {
        let fieldsBlank = firstName === "" && lastName === ""
        // && email === "";
        if (fieldsBlank) {
            setAllFieldsBlank(true);
        } else {
            setAllFieldsBlank(false);
        }
        if (isNameValid(firstName) && isNameValid(lastName)
            // && isEmailValid(email)
        ) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    }, [firstName, lastName,
        // email
    ]);

    useEffect(() => {
        setFirstName(selectedUser?.firstName ? selectedUser.firstName : "");
        setLastName(selectedUser?.lastName ? selectedUser.lastName : "");
        setTitle(selectedUser?.title ? selectedUser.title : "");
        setEmail(selectedUser?.email ? selectedUser.email : "");
        setAdmin(selectedUser?.role === "admin" ? true : false);
        setStatus(selectedUser?.status ? selectedUser.status : "");
    }, [selectedUser]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [navigate]);

    const EditUser = async () => {
        const token = await getAccessTokenSilently();

        // firstName, lastName, title, email, admin
        const user = {
            firstName: firstName,
            lastName: lastName,
            title: title,
            email: email,
            admin: admin,
            status: status
        }

        const validUsersToEdit = checkValidUsers([user]);


        if (validUsersToEdit.length > 0) {
            // setNumEmailsSent(validUsersToEdit.length)
            // only send valid emails
            const result = await fetchData({
                url: import.meta.env.VITE_UNEVU_API_URL + "/user/update/other",
                method: "POST",
                domain: config?.name,
                token: token,
                code: 'null',
                body: { user: validUsersToEdit[0] }
            });

            if (result.status === 200) {
                setUserUpdated(true)
                setUserError(false)
                triggerFunction();
            } else {
                setUserUpdated(false)
                setUserError(true)
            }
        } else {
            setEmailsError(true)
        }

        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiDialog-container': {},
                '& .MuiDialog-paper': { height: '60%', width: '40%', maxWidth: '400px', minWidth: '270px' },
            }}
        >
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                minHeight: 0,
                color: currentTheme.onSurface,
                background: currentTheme.surface,

            }}>
                <Stack direction="row" sx={{ alignItems: 'center', flex: '0 0 auto', justifyContent: 'space-between' }}>
                    <StyledTitle color={currentTheme.primary}>Edit User</StyledTitle>
                    <IconButton onClick={handleClose}>
                        <XIcon style={{ color: currentTheme.onSecondary }} />
                    </IconButton>
                </Stack>
                <Stack spacing={3} sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
                    <Stack spacing={3}>
                        <Card sx={{ borderRadius: 1, background: currentTheme.secondary }} variant="outlined">

                        </Card>
                    </Stack>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <StyledTextField
                            value={firstName}
                            label={"First Name"}
                            onChange={(event: { target: { value: string; }; }) => setFirstName(event.target.value)}
                            error={allFieldsBlank || firstName === "" ? false : !isNameValid(firstName)}
                        />
                        <StyledTextField
                            value={lastName}
                            label={"Last Name"}
                            onChange={(event: { target: { value: string; }; }) => setLastName(event.target.value)}
                            error={allFieldsBlank || lastName === "" ? false : !isNameValid(lastName)}
                        />
                        <StyledTextField
                            value={title}
                            label={"Title"}
                            onChange={(event: { target: { value: string; }; }) => setTitle(event.target.value)}
                            error={false}
                        />
                        {/* <StyledTextField
                            value={email}
                            label={"Email"}
                            onChange={(event: { target: { value: string; }; }) => setEmail(event.target.value)}
                            error={allFieldsBlank || email === "" ? false : !isEmailValid(email)}
                        /> */}
                        <FormControlLabel
                            sx={{ color: currentTheme.primary, '.MuiFormControlLabel-label.Mui-disabled': { color: currentTheme.secondary }}}
                            control={
                                <Switch
                                    style={{ color: currentTheme.primary }}
                                    onChange={(event: { target: { checked: boolean; }; }) => {
                                        setAdmin(event.target.checked)
                                    }}
                                    checked={admin}
                                    disabled={selectedUser?.email === user?.email} // ensure user cannot change their own role
                                />
                            }
                            label="Admin"
                        />
                    </Box>
                    <Stack spacing={3}>
                        <Card sx={{ borderRadius: 1, background: currentTheme.secondary }} variant="outlined">
                            <Box sx={{ overflowX: 'auto' }}>
                                {/* <LineItemsTable rows={lineItems} /> */}
                            </Box>

                            {/* <Divider sx={{color: currentTheme.secondary}} /> */}
                        </Card>
                        <StyledButton onClick={EditUser} text={"Save"} enabled={enabled} />
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default EditUserModal;