import { useAuth0 } from "@auth0/auth0-react";
import { Link, Navigate } from 'react-router-dom';
import { TopNavbar } from "../../components/navigation";
import { PageLoader } from "../../components/page-loader";
import { StyledText } from "../../components/text";
import fetchData from "../../helpers/requests";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultConfig, useTheme } from "../../themes/ThemeProvider";
import { Config } from "../../helpers/types";
import { configsEqual } from "../../helpers/functions";
import ReturnPage from "./ReturnPage";
import { BillingFooter } from "./components";

const BillingReturnPage = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { config, setConfig } = useTheme();
    const [loadingConfig, setLoadingConfig] = useState(true);
    const [allowRedirect, setAllowRedirect] = useState(false);
    const { currentTheme } = useTheme();

    const getDashboardConfig = async (config: Config, setConfig: Dispatch<SetStateAction<Config>>) => {
        const token = await getAccessTokenSilently();

        // have user, give domain -> Make endpoint
        const userDomain = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/user", // /user?email='<string>' once endpoint is ready
            method: "GET",
            token: token
        })

        const domainConfig = await fetchData({
            url: import.meta.env.VITE_UNEVU_API_URL + "/domain/config", // /user?email='<string>' once endpoint is ready
            method: "GET",
            domain: userDomain?.data?.domains ? userDomain?.data?.domains[0] : null,
            token: token
        })

        if (domainConfig && domainConfig.data != null) {
            if (!configsEqual(config, domainConfig.data)) {
                setConfig(domainConfig.data);
                const stringifiedDomainConfig = JSON.stringify(domainConfig.data)
                localStorage.setItem("config", stringifiedDomainConfig)
            }
            // Perform additional processing here
        } else {
            setConfig(defaultConfig)
        }
        setLoadingConfig(false);
    }

    if (isLoading) {
        return <PageLoader />
    }

    if (!isAuthenticated) {
        return <Navigate replace to="/login" />
    }

    useEffect(() => {
        getDashboardConfig(config, setConfig);
    }, []);

    useEffect(() => {
        if (!loadingConfig) {
            setAllowRedirect(true);
        }
    }, [loadingConfig]);

    if (allowRedirect && !config.connected) {  // OR USER AN ADMIN
        return <Navigate replace to="/configuration" />
    }

    return (
        <>
            {config.connected ?
                <>
                    <TopNavbar menu={true} title={"Payment & Billing Information - Checkout"} />
                    <ReturnPage />
                    <BillingFooter />
                </> : null
            }
        </>
    );
}

export default BillingReturnPage;