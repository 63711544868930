import { PencilSimple as PencilSimpleIcon } from '@phosphor-icons/react/dist/ssr/PencilSimple';
import { useTheme } from '../../themes/ThemeProvider';

const PencilIcon = () => {
    const { currentTheme } = useTheme();
    return (
        <PencilSimpleIcon
            style={{color: currentTheme.primary, }}
        />
    );
};

export default PencilIcon;