import { Grid } from "@mui/material";
import StyledTextField from "../../../StyledTextField";
import { Dispatch, SetStateAction, useState } from "react";
import { Config } from "../../../../../helpers/types";
import { TestConnectionButton } from "../../../../buttons";
import InfoIcon from '@mui/icons-material/Info';
import BasicTooltip from "../../../UserFeedback/BasicTooltip";
import { StyledText } from "../../../../text";
import { Link } from "react-router-dom";
import { isEmailValid } from "../../../../../helpers/functions";

interface APIKeyCredentialsFormProps {
    config: Config;
    setConfig: Dispatch<SetStateAction<Config>>;
    apikeyEmail: string;
    setApikeyEmail: Dispatch<SetStateAction<string>>;
    apikey: string | undefined;
    setApikey: Dispatch<SetStateAction<string>>;
    connectionType: string;
    domain: string;
    enableError: boolean;
}

const APIKeyCredentialsForm = ({
    config,
    setConfig,
    apikeyEmail,
    setApikeyEmail,
    apikey,
    setApikey,
    connectionType,
    domain,
    enableError
}: APIKeyCredentialsFormProps) => {
    const [ error, setError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");

    function handleSubmit(event: { target: { value: string; }; }, setApikey: Dispatch<SetStateAction<string>>) {
        const email = event.target.value;

        if (isEmailValid(email)) {
            setError(false);
            setApikey(event.target.value);
        } else {
            setError(true);
            setErrorMessage("Invalid email address");
            setApikey(event.target.value);
        }
    }

    return (
        <Grid container>
            <Grid item xs={3} md={3}>
            </Grid>
            <Grid item xs={6} md={6}>
                <StyledTextField
                    disabled={config?.loggedIn ? config?.loggedIn : false}
                    value={apikeyEmail}
                    id="standard-error-helper-text"
                    helperText={errorMessage}
                    error={error}
                    variant="filled"
                    label={"API Key Owner:"}
                    onChange={(event: { target: { value: string; }; }) => handleSubmit(event, setApikeyEmail)}
                    width={"100%"}
                />
            </Grid>
            <Grid item xs={1} md={1}>
                <BasicTooltip
                    sx={{
                        marginTop: "14px",
                        marginLeft: "10px"
                    }}
                    title={"The email of the user that created the API token. (Ideally, this would be an admin user on Zendesk)"}
                    icon={
                        <InfoIcon color="primary" />
                    }
                />
            </Grid>
            <Grid item xs={2} md={2}>
            </Grid>
            <Grid item xs={3} md={3}>
            </Grid>
            <Grid item xs={6} md={6}>
                <StyledTextField
                    disabled={config?.loggedIn ? config?.loggedIn : false}
                    value={apikey}
                    error={enableError}
                    id="standard-error-helper-text"
                    helperText={errorMessage}
                    variant="filled"
                    label={"API Key:"}
                    onChange={(event: { target: { value: string; }; }) => setApikey(event.target.value)}
                    width={"100%"}
                    type={"password"}
                />
            </Grid>
            <Grid item xs={3} md={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <StyledText secondaryText marginLeft={"14px"} marginTop={"10px"} >
                    <Link
                        to="https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API"
                    >
                        {"How to get your API key?"}
                    </Link>
                </StyledText>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <TestConnectionButton
                    setTestPassed={false}
                    domain={domain}
                    apikey={apikey}
                    adminEmail={apikeyEmail}
                    connectionType={connectionType}
                    disabled={error || enableError || !apikey || !apikeyEmail}
                />
            </Grid>
        </Grid>
    );
    
}

export default APIKeyCredentialsForm;
