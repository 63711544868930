import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';
import { useTheme } from '../../themes/ThemeProvider';

type CustomButtonProps = {
    onClick: () => void | Dispatch<SetStateAction<boolean>> | Promise<void>;
    text: string;
    enabled?: boolean;
    marginTop?: string;
};

const StyledButton: React.FC<CustomButtonProps> = ({ onClick, text, enabled, marginTop }) => {
    const { currentTheme } = useTheme();

    const style = {
        padding: "4px 10px",
        margin: "3px",
        marginTop: marginTop ? marginTop : null,
        borderRadius: "4px",
        color: currentTheme.onPrimary,
        backgroundColor: currentTheme.primary,
        '&:hover': {
            color: currentTheme.onSecondary,
            backgroundColor: currentTheme.secondary,
        },
        spacing: 1
    }

    return (
        enabled ? <Button sx={style} onClick={onClick}>{text}</Button> : <Button sx={style} onClick={onClick} disabled>{text}</Button>
    );
};

export default StyledButton;