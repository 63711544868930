import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTheme } from "../../../../themes/ThemeProvider";
import { isDomainStringValid } from "../../../../helpers/functions";
import OAuthCredentialsForm from "./OAuth/OAuthCredentialsForm";
import APIKeyCredentialsForm from "./APIKey/APIKeyCredentialsForm";

interface ChildComponentProps {
    domain: string;
    setDomain: Dispatch<SetStateAction<string>>;
    apikey: string | undefined;
    setApikey: Dispatch<SetStateAction<string>>;
    apikeyEmail: string;
    setApikeyEmail: Dispatch<SetStateAction<string>>;
    connectionType: string;
}

const ZendeskLogin: React.FC<ChildComponentProps> = ({
    domain,
    setDomain,
    apikey,
    setApikey,
    apikeyEmail,
    setApikeyEmail,
    connectionType
}) => {
    const [errorMessage, setErrorMessage] = useState("")
    const [enableLogin, setEnableLogin] = useState(false)
    const [enableError, setEnableError] = useState(false)
    const { config, setConfig } = useTheme();

    useEffect(() => {
        if (config != null) {
            // onChange(config?.name)
            setEnableLogin(!config?.loggedIn)
        } else {
            console.log("No config found in state")
        }
    }, []);

    const onChange = (newString: string) => {
        { apikey !== undefined ? setApikey(newString) : setDomain(newString) }
        if (isDomainStringValid(newString)) {
            setEnableLogin(true);
            setEnableError(false)
        } else {
            setEnableLogin(false);
            if (newString != "") {
                setEnableError(true);
                setErrorMessage(connectionType === "oauth" ? "Invalid domain name" : "API Key should be more than 3 characters long");
            } else {
                setEnableError(false);
            }
        }
    }

    return (
        <div>
            {
                connectionType === "oauth" ?
                    <OAuthCredentialsForm
                        config={config}
                        setConfig={setConfig}
                        onChange={onChange}
                        enableLogin={enableLogin}
                        domain={domain}
                        enableError={enableError}
                        errorMessage={errorMessage}
                    />
                    :
                    <APIKeyCredentialsForm
                        config={config}
                        setConfig={setConfig}
                        apikeyEmail={apikeyEmail}
                        setApikeyEmail={setApikeyEmail}
                        apikey={apikey}
                        setApikey={setApikey}
                        connectionType={connectionType}
                        domain={domain}
                        enableError={enableError}
                    />
            }
        </div>
    );
}

export default ZendeskLogin;