import React from 'react';
import { Clock } from '@phosphor-icons/react/dist/ssr/Clock';
import { useTheme } from '../../themes/ThemeProvider';

interface ClockIconProps {
    weight?: 'fill' | 'outline';
}

const ClockIcon: React.FC<ClockIconProps> = ({ weight }) => {
    const { currentTheme } = useTheme();
    return (
        <Clock
            style={{ fontWeight: weight === 'fill' ? 'bold' : 'normal', color: currentTheme.onPrimary, fontSize: 20} }
        />
    );
};

export default ClockIcon;