import * as React from 'react';
import { Card, CardContent, CardHeader, Chip, Divider, Link, Stack, Typography, Grid, Avatar } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@phosphor-icons/react/dist/ssr/CheckCircle';
import { CreditCard as CreditCardIcon } from '@phosphor-icons/react/dist/ssr/CreditCard';
import { PropertyItem } from '../../../components/core/property-item';
import { PropertyList } from '../../../components/core/property-list';
import { useTheme } from '../../../themes/ThemeProvider';

export default function BillingDetails(props) {
  const { nextDate, pricingTier, pricingTierName } = props;
  const { config, currentTheme } = useTheme()
  const originalDate = config?.tier === pricingTier ? config?.trialEndDate : nextDate ? nextDate : "N/A";
  const formattedDate = originalDate != "N/A" ? new Date(originalDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : "N/A";

  return (
    <React.Fragment >
      <Stack spacing={2} style={{ width: "48.5vw" }} >
        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column'

            }}
          >
            <Stack spacing={4}>
              <Card
                sx={{
                  border: '1px solid rgba(0,0,0,0.1);',
                  borderRadius: '10px',
                  background: currentTheme.surface,
                  color: currentTheme.onSurface
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar sx={{
                      bgcolor: currentTheme.primary,
                      boxShadow: 'var(--mui-shadows-8)',
                    }} >
                      <CreditCardIcon sx={{ color: currentTheme.raised, background: currentTheme.onRaised }} fontSize="var(--Icon-fontSize)" />
                    </Avatar>
                  }
                  title="Subscription Information"
                />
                <CardContent>
                  <Card variant="outlined" sx={{ border: `1px solid ${currentTheme.background}` }}>
                    <PropertyList
                      divider={<Divider sx={{ color: currentTheme.onSurface, background: currentTheme.background }} />}
                      sx={{
                        '--PropertyItem-padding': '12px 24px',
                        height: '100%',
                        background: currentTheme.raised,
                        color: currentTheme.onRaised
                      }}
                    >
                      {[
                        { key: 'Name', value: <Link sx={{ color: 'dodgerblue' }} variant="subtitle2">{config?.user?.firstName ? config?.user?.firstName : "" + " " + config?.user?.lastName ? config?.user?.lastName : ""}</Link> },
                        {
                          key: 'Organization',
                          value: (
                            <Typography variant="subtitle2">
                              {config?.organization?.name}
                            </Typography>
                          ),
                        },
                        {
                          key: 'Current Plan',
                          value: (
                            <Chip
                              icon={<CheckCircleIcon color={currentTheme.onPrimary} weight="fill" />}
                              label={pricingTierName}
                              size="small"
                              variant="outlined"
                              sx={{ 
                                color: currentTheme.onPrimary, 
                                background: config?.tier === pricingTier ? currentTheme.secondary : config?.tier === 'cancel-pending' || config?.tier === 'expired' ? 'darkred' : currentTheme.primary, border: `1px solid ${currentTheme.background}` 
                              }}
                            />
                          ),
                        },
                        {
                          key: config?.tier === pricingTier ? 'Date Free Trial Ends' : config?.tier === 'cancel-pending' ? 'Last Date Accessible' : 'Next Payment Date',
                          value: formattedDate
                        }
                      ].map((item) => (
                        <PropertyItem key={item.key} name={item.key} value={item.value} />
                      ))}
                    </PropertyList>
                  </Card>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
}