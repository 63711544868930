import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTheme } from '../../themes/ThemeProvider';

const StyledTextField = (props:any) => {
    const { 
        label, 
        onChange, 
        error, 
        id, 
        defaultValue, 
        value, 
        width, 
        required, 
        disabled, 
        type,
        helperText 
    } = props;

    const { currentTheme } = useTheme();

    const style = { 
        backgroundColor: currentTheme.raised,
        input: { color: currentTheme.onRaised },
        '& label': {
          color: currentTheme.secondary,
        },
        '& label.Mui-focused': {
          color: currentTheme.primary,
        },
        '& label.Mui-disabled': {
          color: currentTheme.onSecondary,
        },
        "& .MuiOutlinedInput-root": {
            '& fieldset': {
                borderColor: currentTheme.secondary,
            },
            '&:hover fieldset': {
                borderColor: currentTheme.secondary,
            },
            "&.Mui-focused fieldset": {
                borderColor: currentTheme.primary,
            }, 
            "&.Mui-disabled": {
                background: currentTheme.secondary,
            },
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: currentTheme.onSecondary,
          },
    }

    return (
        <Box
            component="form"
            sx={{
            '& > :not(style)': { m: 1, width: width ? width : '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                helperText={error ? helperText : null}
                error={error}
                id={id}
                sx={style}
                label={label}
                value={value}
                type={type ? type : ""}
                variant="outlined"
                onChange={onChange}
                disabled={disabled ? disabled : false}
                required={required ? required: false}
            />
            {/* id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password" */}
        </Box>
    );
}

export default StyledTextField;