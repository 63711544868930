import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '../../themes/ThemeProvider';
import { useAuth0 } from '@auth0/auth0-react';
import CustomizedSnackbars from '../forms/UserFeedback/CustomizedSnackbars';

const TestConnectionButton = (props: any) => {
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("error")
  const [alertMessage, setAlertMessage] = useState("Success!")
  const { getAccessTokenSilently } = useAuth0();
  const {
    testPassed,
    setTestPassed,
    domain,
    apikey,
    connectionType,
    adminEmail,
    disabled
  } = props;

  const { config, setConfig } = useTheme();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [text, setText] = useState("Test Connection")
  const timer = useRef<number>();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    })
  };

  useEffect(() => {
    initiateButtonStateFromConfig();
    return () => {
      clearTimeout(timer.current);
    };
  }, [testPassed]);

  const setSuccessState = async () => {
    const connectionSecure = await testConnectionToZendesk(connectionType, adminEmail, apikey, domain)
    if (connectionSecure !== success) { setSuccess(connectionSecure); }

    setLoading(false);
    setText(connectionSecure ? "Connection Secure" : "Retry Connection")
  }

  const initiateButtonStateFromConfig = () => {
    if (config?.connected) {
      setSuccessState()
    }
  }

  const testConnectionToZendesk = async (
    connectionType: string,
    adminEmail: string,
    apikey: string,
    domain: string
  ) => {
    setShowAlert(false)
    try {
      const token = await getAccessTokenSilently();

      const queryParams = new URLSearchParams({
        connectionType,
        adminEmail,
        apikey,
        domain
      });

      const response = await fetch(`${import.meta.env.VITE_UNEVU_API_URL}/${connectionType === "oauth" ? "oauth" : "api"}/zendesk/${connectionType === "oauth" ? "test_connection" : "key"}?${queryParams}`,
        {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        const json = await response.json();
        setAlertType("error")
        setAlertMessage(json.error.msg)
        setShowAlert(true)
        throw new Error(`Error: ${json.error.msg}`);
      } else {
        setAlertType("success")
        setAlertMessage("Success!")
        setShowAlert(true)
      }

      const domainConfig = await response.json();

      let newConfig = { ...config }; // Assuming 'config' is defined elsewhere
      newConfig.connected = domainConfig.status === 200;

      if (config.connected !== newConfig.connected || config.loggedIn !== newConfig.loggedIn) {
        newConfig["loggedIn"] = true;
        setConfig(newConfig);
        localStorage.setItem("config", JSON.stringify(newConfig));
      }
      if (testPassed !== newConfig.connected) { setTestPassed(newConfig.connected); } // Assuming setTestPassed is defined elsewhere
      return newConfig.connected;
    } catch (error) {
      console.error("Failed to test connection to Zendesk:", error);
      return false;
    }
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccessState()
      }, 500);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          sx={buttonSx}
          disabled={loading || disabled || success}
          onClick={success ? () => { } : handleButtonClick}
        >
          {text}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      {showAlert ? <CustomizedSnackbars resetFunc={setShowAlert} severity={alertType} message={alertMessage} /> : null}
    </Box>
  );
}

export default TestConnectionButton;